import {inject, Injectable} from '@angular/core';
import {HttpClient}         from "@angular/common/http";
import {ConfigService}      from "../config.service";
import {lastValueFrom}      from "rxjs";

@Injectable({
              providedIn: 'root'
            })
export class AccountSubscriptionService {
  protected http = inject(HttpClient);

  constructor() {
  }

  get url(): string {
    return '/api/account/' + ConfigService.accountId.getValue() + '/subscription';
  }

  public async activate(): Promise<void> {
    return await lastValueFrom(this.http.put<void>(this.url + '/activate', {}));
  }
}
