import {Address} from "./address";

export enum AccountType {
  home = 'Homeschool',
  coop = 'Co-op'
}

export enum AccountStatus {
  active    = 'active',
  cancelled = 'cancelled',
  trial     = 'trial'
}

export class Account {
  mailingAddress: Address;
  billingAddress: Address;
  id: number;
  name: string;
  createdAt: string;
  createdBy: number;
  type: AccountType;
  timeZone: string;
  facilitator: string;
  facilitatorTitle: string;
  email: string;
  phone: string;
  expires: string;
  billingRef: string;
  isInitiallyReviewed: boolean;
  status: string;
  code: string;
  constructor() {
    this.mailingAddress      = new Address();
    this.billingAddress      = new Address();
    this.id                  = -1;
    this.name                = '';
    this.createdAt           = '';
    this.createdBy           = -1;
    this.type                = AccountType.home;
    this.timeZone            = 'America/Chicago';
    this.facilitator         = '';
    this.facilitatorTitle    = 'Administrator';
    this.email               = '';
    this.phone               = '';
    this.expires             = '';
    this.billingRef          = '';
    this.isInitiallyReviewed = true;
    this.status              = AccountStatus.active;
    this.code = '';
  }
}

export interface AccountList {
  id: number;
  name: string;
  createdAt: string;
  type: AccountType;
  status: string;
  code: string;
  canManagePayments: boolean;
}

// Create a mapper to map Account to AccountList
export function mapAccountToAccountList(account: Account): AccountList {
  return {
    id: account.id,
    name: account.name,
    createdAt: account.createdAt,
    type: account.type,
    status: account.status,
    code: account.code,
    canManagePayments: false
  };
}
