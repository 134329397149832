import {inject, Injectable} from '@angular/core';
import {Router}             from '@angular/router';
import {lastValueFrom}      from 'rxjs';
import {ConfigService}      from "./config.service";
import {UserClazzService}   from "./db/user-clazz.service";

export enum AccessCode {
  IsSuperuser             = "superuser", // No permitted access will match, so superuser will only be true
  IsAdmin                 = "admin", // No permitted access will match, so admin will only be true
  ReadAllCalendars        = "read:calendarsAll",
  ManagePlannedTimedOff   = "manage:plannedTimeOff",
  ManageAllAbsences       = "manage:absencesAll",
  ManageOwnAbsences       = "manage:absencesOwn",
  ManageAllClasses        = "manage:classesAll",
  ManageLessonPlans       = "manage:lessonPlans",
  ReadAllClasses          = "read:classesAll",
  ManageAccountSettings   = "manage:accountSettings",
  ManageAccountUsers      = "manage:accountUsers",
  ManageAccountAdminUsers = "manage:accountAdmins",
  ManageAccountRoles      = "manage:accountRoles",
  ReadAllReportCards      = "read:reportCardsAll",
  ReadAllTranscripts      = "read:transcriptsAll",
  Unknown                 = ""
}

const log = false;

@Injectable({
              providedIn: 'root'
            })
export class PermissionValidationService {
  private router = inject(Router);
  private userClazzService = inject(UserClazzService);

  async authorizePermission(
    code: AccessCode,
    redirectOnFail = false): Promise<boolean> {
    log ? console.log("Checking permission: " + code) : null;
    return await this.authorizePermissions([code], redirectOnFail);
  }

  /**
   * This will look at the currently selected account
   * Be aware if this before the user could select an account, you should use the accountId version
   * @param code
   * @param redirect
   */
  async authorizePermissions(
    code: AccessCode[],
    redirect = false): Promise<boolean> {
    if (code.some(c => c == AccessCode.IsSuperuser)) {
      if (ConfigService.user.isSuperuser) {
        log ? console.log("Approved access: " + code) : null;
      } else {
        log ? console.log("Denied access: " + code, ConfigService.user) : null;
      }
      return ConfigService.user.isSuperuser;
    }
    if (ConfigService.user.isSuperuser) {
      return true;
    }
    return await ConfigService.getCurrentUserAccount().then(ua => {
      if (
        ua.status === 'active' &&
        // Look at user access
        (ua.isAdmin || code.every(c => ua.userAccountAccesses.find(role => c == role.code))) ||
        // Look at role access
        (ua.role && (ua.role.admin || code.every(c => ua.role && ua.role.roleAccess.find(role => c == role.code))))
      ) {
        log ? console.log("Approved access: " + code) : null;
        return true;
      } else {
        log ? console.log("Denied access: " + code, ua) : null;
        if (redirect) {
          this.router.navigate(['/dashboard']);
        }
        return false;
      }
    });
  }

  /**
   * This will look at the currently selected account
   * @param accountId - The account to check
   * @param code - The access code to check
   * @param redirectPath - If set, will redirect to this path (ex: '/dashboard') if access is denied
   */
  async authorizeAccountPermissions(
    accountId: number,
    code: AccessCode[],
    redirectPath: string | any[] | null  = null): Promise<boolean> {
    log ? console.log("Checking permissions2" + code) : null;
    if (ConfigService.user.isSuperuser) {
      return true;
    }
    const auth = await lastValueFrom(ConfigService.userAccounts).then(uas => {
      const ua = uas.find(ua => ua.accountId === accountId);
      if (!ua) {
        log ? console.log("Denied access: " + accountId + " not found") : null;
        return false;
      }
      if (
        ua.status === 'active' &&
        // Look at user access
        (ua.isAdmin || code.every(c => ua.userAccountAccesses.find(role => c == role.code))) ||
        // Look at role access
        (ua.role && (ua.role.admin || code.every(c => ua.role && ua.role.roleAccess.find(role => c == role.code))))
      ) {
        log ? console.log("Approved access: " + code) : null;
        return true;
      } else {
        log ? console.log("Denied access: " + code, ua) : null;
        return false;
      }
    });
    if (!auth) {
      if (redirectPath) {
        if (Array.isArray(redirectPath)) {
          this.router.navigate(redirectPath);
        } else {
          this.router.navigate([redirectPath]);
        }
      }
    }
    return auth;
  }

  /**
   * This will set the ConfigService's accountId in case it hasn't been set yet.  This is required as this is
   * used to poll the server for the account's class permissions.
   * @param accountId
   * @param classId
   */
  async authorizeClazzPermissions(accountId: number, classId: number) {
    // We need to set the accountId in case of a page refresh where the component that populates the accountId
    // hasn't loaded yet
    if (ConfigService.accountId.getValue() == null || ConfigService.accountId.getValue()! < 0) {
      ConfigService.accountId.next(accountId);
    }
    if (ConfigService.user.isSuperuser) {
      return true;
    }
    let auth = await this.authorizeAccountPermissions(accountId, [AccessCode.ManageAllClasses, AccessCode.ManageLessonPlans]);
    if (!auth) {
      auth = await this.userClazzService.getTeachers(await this.userClazzService.list(classId)).includes(ConfigService.user.id);
    }
    return auth;
  }
}
