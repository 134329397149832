export enum SubjectEnum {
  math                        = 'Math',
  history                     = 'History',
  english                     = 'English Language Arts',
  science                     = "Science",
  socialStudies               = "Social Studies",
  computerScience             = "Technology",
  foreignLanguage             = 'Foreign Language',
  pe                          = "PE",
  business                    = "Health",
  art                         = "Art",
  music                       = "Music",
  fineArts                    = "Fine Arts",
  agriculture                 = "Agriculture",
  careerAndTechnicalEducation = 'Career and Technical Education',
  religion                    = 'Religion',
  other                       = "Other",
}

export class Clazz {
  //} implements IClazz {
  id: number;
  accountId: number;
  name: string;
  color: string;
  credits: number;
  onSunday: boolean;
  onMonday: boolean;
  onTuesday: boolean;
  onWednesday: boolean;
  onThursday: boolean;
  onFriday: boolean;
  onSaturday: boolean;
  typicalStartTime: string | null;
  typicalEndTime: string | null;
  subject: SubjectEnum | null;
  startDt: string;
  endDate: string;
  notes: string;
  schoolYearId: number | null;
  isSemester1: boolean;
  isSemester2: boolean;
  isHighSchool: boolean;
  isHonors: boolean;
  isAp: boolean;
  publisherId: number | null;
  courseId: number | null;
  sourceId: number | null;

  constructor() {
    this.id               = -1;
    this.accountId        = -1;
    this.name             = '';
    const colors          = ['#FDAC53', '#9BB7D4', '#B55A30', '#F5DF4D', '#0072B5', '#A0DAA9', '#E9897E', '#00A170', '#926AA6', '#D2386C'];
    this.color            = colors[Math.floor(Math.random() * colors.length)];
    this.credits          = 0;
    this.onSunday         = false;
    this.onMonday         = false;
    this.onTuesday        = false;
    this.onWednesday      = false;
    this.onThursday       = false;
    this.onFriday         = false;
    this.onSaturday       = false;
    this.startDt          = '';
    this.endDate          = '';
    this.typicalStartTime = null;
    this.typicalEndTime   = null;
    this.subject          = null;
    this.notes            = "";
    this.schoolYearId     = null;
    this.isSemester1      = true;
    this.isSemester2      = true;
    this.isHighSchool     = false;
    this.isHonors         = false;
    this.isAp             = false;
    this.publisherId      = null;
    this.courseId         = null;
    this.sourceId         = null;
  }
}
