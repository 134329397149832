import {inject, Injectable} from '@angular/core';
import {DatabaseService}    from "./database.service";
import {lastValueFrom}      from "rxjs";
import {ConfigService}      from "./config.service";

@Injectable({
              providedIn: 'root'
            })
export class AppSetupService {

  private databaseService = inject(DatabaseService);

  constructor() {
  }

  async loadFromServer() {
    const observable           = this.databaseService
      .getMyUser();
    const user                 = await lastValueFrom(observable);
    ConfigService.user         = user;
    ConfigService.userAccounts = user.userAccounts;
    return true;
  }
}
