import {ChangeDetectorRef, Component, inject, Inject} from '@angular/core';
import {AuthService}                                  from '@auth0/auth0-angular';
import {DOCUMENT}                                     from '@angular/common';
import {DatabaseService}                              from 'src/app/core/services/database.service';
import {User}                                         from "../../core/models/db/user";
import {SchoolYearService}                            from "../../core/services/db/school-year.service";
import {DashboardService}                             from "../../core/services/db/dashboard.service";
import {DashboardClass}                               from "../../core/models/db/dashboard-class";
import {SchoolYear}                                   from "../../core/models/db/school-year";
import {isSafari16OrEarlier, parseDateLocal}          from "../../core/utilities";
import {filter}                                       from "rxjs/operators";
import {ConfigService}                                from "../../core/services/config.service";
import {takeUntilDestroyed}                           from "@angular/core/rxjs-interop";
import {AccountService}                               from "../../core/services/db/account.service";
import {Weather}                                      from "../../core/models/db/weather";
import {Today}                                        from "../../core/models/db/dashboard/today";
import {ClazzSummary}                                 from "../../core/models/db/dashboard/class-summary";
import {StudentSummary}                               from "../../core/models/db/dashboard/student-summary";
import {RouteGenService}                              from "../../core/services/route-gen.service";
import {SetupProgress}                       from "../../core/models/db/dashboard/setup-progress";
import {Account, AccountStatus, AccountType} from "../../core/models/db/account";
import * as moment                           from "moment-timezone";
import {interval, switchMap, take}                    from "rxjs";
import {HomeschoolSetup}                              from "../../core/models/db/homeschool-setup";
import {GradePipe}                                    from "../../core/pipes/grade.pipe";
import {SelectOption}                                 from "../../shared/select/select.component";
import {AccountSetupService}                          from "../../core/services/db/account-setup.service";
import {AccessCode, PermissionValidationService}      from "../../core/services/permission-validation.service";
import {AccountSubscriptionService}                   from "../../core/services/db/account-subscription.service";
import {ConfirmationService}                          from "../../core/services/confirmation.service";
import {NotificationService}                          from "../../core/services/notification.service";
import {EmailService}                                 from "../../core/services/db/email.service";

@Component({
             selector: 'app-dashboard',
             templateUrl: './dashboard.component.html',
             styleUrls: ['./dashboard.component.scss'],
           })
export class DashboardComponent {
  classSummaries: DashboardClass[]                          = [];
  schoolYear: SchoolYear                                    = new SchoolYear();
  daysLeft                                                  = 0;
  user: User                                                = new User();
  studentSummaries: StudentSummary[]                        = [];
  assignmentsToday                                          = 0;
  isTodayLoading                                            = true;
  isSummaryLoading                                          = true;
  metadata: any;
  weatherData: Weather | undefined;
  showRescheduleDialog                                      = false;
  console                                                   = console;
  today: Today | null                                       = null;
  clazzSummaries: ClazzSummary[]                            = [];
  routeGenService                                           = inject(RouteGenService);
  noAccount                                                 = true;
  setupProgress: SetupProgress | null                       = null;
  account: Account | null                                   = null;
  homeschoolSetup                                           = new HomeschoolSetup();
  timeZoneOptions: { displayText: string, value: string }[] = [];
  gradeOptions: SelectOption[]                              = [];
  gradePipe                                                 = inject(GradePipe);
  showHomeschoolSetup                                       = false;
  isConfiguringSetup                                        = false;
  accountSetupService                                       = inject(AccountSetupService);
  public browserSafari16OrEarlier                           = isSafari16OrEarlier();
  accountIsTrial: boolean | null                            = null;
  isAdmin: boolean | null                                   = null;
  accountIsInactive                                         = false;
  isPolling: boolean | null                                 = false;
  isSuperuser                                               = false;
  message                                                   = '';
  emailService                                              = inject(EmailService);
  emailError                                                = false;
  emailErrorMessage: string                                 = '';
  protected routerGenService                                = inject(RouteGenService);
  private schoolYearService                                 = inject(SchoolYearService);
  private dashboardService                                  = inject(DashboardService);
  private accountService                                    = inject(AccountService);
  private permissionService                                 = inject(PermissionValidationService);
  private cdr                                               = inject(ChangeDetectorRef);
  private accountSubscriptionService                        = inject(AccountSubscriptionService);
  private confirmationService                               = inject(ConfirmationService);
  private notificationService                               = inject(NotificationService);

  constructor(
    @Inject(DOCUMENT) public document: Document,
    public auth: AuthService,
    public db: DatabaseService
  ) {
    for (let i = -1; i <= 12; i++) {
      this.gradeOptions.push({value: i, displayText: this.gradePipe.transform(i)});
    }

    let usTimeZones = moment.tz.zonesForCountry('US');

    this.timeZoneOptions = usTimeZones.map(timeZone => {
      return {displayText: timeZone, value: timeZone};
    });

    ConfigService.accountId
      .pipe(
        takeUntilDestroyed(),
        filter(accountId => (accountId !== null && accountId > 0))
      )
      .subscribe(async (accountId) => {
        this.accountService.get(accountId!).then(async account => {
          this.account           = account;
          this.accountIsInactive = !await this.accountService.isActive(accountId!);
          this.accountIsTrial    = account.status == AccountStatus.trial;
          this.isAdmin           = await this.permissionService.authorizeAccountPermissions(accountId!, [AccessCode.IsAdmin]);
          if (!this.account.isInitiallyReviewed) {
            let userTimeZone      = Intl.DateTimeFormat().resolvedOptions().timeZone;
            this.account.timeZone = String(this.timeZoneOptions.find(option => option.value === userTimeZone)!.value);
          }
        });
        this.setupProgress = null;
        this.noAccount     = false;
        this.refresh()
      });
  }

  updateSubscription() {
    this.confirmationService.confirm('Subscription Activation',
                                     'Are you sure you want to activate your subscription?',
                                     'Yes',
                                     'No',
                                     async () => {
                                       await this.accountSubscriptionService.activate();
                                       this.pollAccountStatus(ConfigService.accountId.value!);
                                     },
                                     () => {
                                     });
  }

  private configService = inject(ConfigService);
  accountType: AccountType | null = null;
  /**
   * This method refreshes all data for the component
   * It should be called whenever the account changes
   */
  async refresh() {
    this.accountType = await this.configService.getAccountType(ConfigService.accountId.value!)

    this.weatherData = undefined;

    this.dashboardService.getSetupProgress(ConfigService.accountId.value!).then(progress => {
      this.setupProgress = progress;
    });

    this.dashboardService.getToday().then(today => {
      this.today          = today;
      this.isTodayLoading = false;
    });

    // Student summaries should only be shown for home accounts
    if (this.accountType == AccountType.home) {
      this.dashboardService.getStudentSummary().then(studentSummaries => {
        this.studentSummaries = studentSummaries;
      });
    } else {
      this.studentSummaries = [];
    }

    this.accountService.getWeather().then(weather => {
      if (weather) {
        this.weatherData = weather;
      }
    });

    this.schoolYearService.getCurrent().then(schoolYear => {
      this.schoolYear = schoolYear;
      if (schoolYear == null) {
        this.daysLeft = 0;
      } else {
        this.daysLeft = Math.ceil((parseDateLocal(schoolYear.endDate).getTime() - Date.now()) / (1000 * 60 * 60 * 24));
        if (this.daysLeft < 0) {
          this.daysLeft = 0;
        }
      }
    });
  }

  sendFeedback() {
    if (this.message === '') {
      this.notificationService.error('Please enter a message');
    } else {
      this.emailService.emailSuggestion(this.message).then(() => {
        this.notificationService.success('Your feedback makes a difference. Thank you for helping us!');
        this.message = '';
      });
    }
  }

  async validateEmail(email: string) {
    if (ConfigService.user.email === email.toLowerCase().trim()) {
      this.emailError        = true;
      this.emailErrorMessage = 'Please enter a different email address';
      this.notificationService.error('The student email cannot be the same as the parent email');
    } else if (email.toLowerCase().trim() === '') {
      this.emailError        = true;
      this.emailErrorMessage = 'Email address is required';
    } else {
      this.emailError        = false;
      this.emailErrorMessage = '';
    }
  }

  ngOnInit() {
    this.db.getMyUser()
      .subscribe(user => this.user = user);
    this.permissionService.authorizePermission(AccessCode.IsSuperuser).then(isSuperuser => this.isSuperuser = isSuperuser);
    this.dashboardService.getClassSummary()
      .then(classes => {
              this.clazzSummaries = classes;
            }
      ).finally(() => this.isSummaryLoading = false);
    this.dashboardService.getClasses()
      .then(classes => {
              this.classSummaries = classes;
            }
      ).finally(() => this.isSummaryLoading = false);
  }

  check() {
    this.auth.getAccessTokenWithPopup().subscribe(res => console.log(JSON.stringify(res)));
  }

  accountReviewed() {
    if (this.account) {
      this.account.isInitiallyReviewed = true;
      this.showHomeschoolSetup         = true;
    }
  }

  setupHomeschool() {
    this.isConfiguringSetup = true;
    this.accountService.update(this.account!).pipe(
      take(1)
    ).subscribe(() => {
      this.accountSetupService.setupHomeschool(this.homeschoolSetup).then(() => {
        this.showHomeschoolSetup = false;
        this.refresh();
      }).catch(() => {
        this.isConfiguringSetup = false
      });
    });
  }

  private pollAccountStatus(accountId: number) {
    this.isPolling = true;
    this.cdr.detectChanges();
    interval(2000).pipe(
      take(10),
      switchMap(() => this.accountService.get(accountId, true))
    ).subscribe({
                  next: account => {
                    if (this.accountIsTrial && account.status == AccountStatus.active) {
                      // Subscription has been activated
                      this.notificationService.success('Thank you!!! Your subscription has been activated!');
                      this.isPolling = false;
                    }
                    this.accountIsTrial = account.status == AccountStatus.trial;
                  },
                  complete: () => {
                    this.isPolling = false;
                  }
                });
  }
}
