<div class="flex flex-nowrap gap-2">
  <md-outlined-text-field (appBlur)="onBlurEvent()"
                          (input)="onValueChange($any($event.target).value)"
                          (wheel)="_type=='number'?$event.preventDefault():{}"
                          [class]="class"
                          [disabled]="disabled"
                          [errorText]="errorMessage"
                          [error]="error"
                          [readOnly]="readOnly"
                          [rows]="rows"
                          [supportingText]="required ? '* This field is required' : ''"
                          [type]="_type"
                          [value]="_value"
                          label="{{label}}"
  [style.width]="error && _type=='number' ? '8rem':''">
    @if (icon != "") {
      <md-icon slot="leading-icon">{{ icon }}</md-icon>
    }
    @if (type == "password") {
      <md-icon-button toggle slot="trailing-icon">
        <md-icon>visibility</md-icon>
        <md-icon slot="selected">visibility_off</md-icon>
      </md-icon-button>
    }
    @if (error) {
      <md-icon slot="trailing-icon">error</md-icon>
    }
  </md-outlined-text-field>
  @if (helpText != "") {
    <md-button (click)="showHelp = !showHelp" class="mt-4">
      <md-icon>help</md-icon>
    </md-button>
  }
</div>
<app-dialog *ngIf="showHelp" [title]="label" (close)="showHelp=false">
  <ng-template>
  <div [innerHTML]="helpText"></div>
  </ng-template>
</app-dialog>
