import {TitleCasePipe}                                                         from '@angular/common';
import {Component, ElementRef, EventEmitter, inject, Input, Output, Renderer2} from '@angular/core';
import {NgxSpinnerService}        from "ngx-spinner";
import {Observable, Subscription} from "rxjs";

export type ButtonType =
  'save'
  | 'delete'
  | 'back'
  | 'new'
  | 'edit'
  | 'cancel'
  | 'accept'
  | 'decline'
  | 'message'
  | 'calendar'
  | 'create'
  | 'next'
  | 'clear all'
  | 'home'
  | 'print'
  | 'close'
  | 'update'
  | 'editCalendar'
  | 'move up'
  | 'move down'
  | 'payments'
  | 'upload'
  | 'download'
  | 'help'
  | 'view'
  | 'complete'
  | 'rotate_left'
  | 'rotate_right'
  | 'reset_image'
  | 'shopping_cart'
  | 'mail'
  | undefined;

@Component({
             selector: 'app-button',
             templateUrl: './button.component.html',
             styleUrl: './button.component.scss'
           })
export class ButtonComponent {
  @Output() click: EventEmitter<any> = new EventEmitter();
  @Input() type: ButtonType          = 'save';
  @Input() disabled: boolean         = false;
  @Input() text: string              = '';
  @Input() disableSmallIcon: boolean = false;
  @Input() iconOnly: boolean         = false;
  @Input() working: Observable<boolean> | null = null;
  private workingSubscription: Subscription | undefined;
  protected _working = false;

  private spinner: NgxSpinnerService = inject(NgxSpinnerService);

  icon_name: string = 'save';
  private el       = inject(ElementRef);
  private renderer = inject(Renderer2);

  constructor() {
  }

  ngOnDestroy() {
    this.workingSubscription?.unsubscribe();
  }

  ngOnInit() {
    if (!this.type) {
      this.type = 'save';
    }
    if (this.text === '') {
      this.text = new TitleCasePipe().transform(this.type);
    }

    if (this.working) {
      this.workingSubscription = this.working.subscribe(show => {
        show ? this.spinner.show() : this.spinner.hide();
        this._working = show;
        this.disabled = show;
      });
    }


    // We default to have the icon the same name as the type,
    // Then we can override below
    this.icon_name = this.type;
    switch (this.type) {
      case "move down":
        this.icon_name = 'arrow_downward';
        break;
      case "move up":
        this.icon_name = 'arrow_upward';
        break;
      case 'clear all':
        this.icon_name = 'clear_all';
        break;
      case 'back':
        this.icon_name = 'navigate_before';
        break;
      case 'next':
        this.icon_name = 'navigate_next';
        break;
      case 'new':
        this.icon_name = 'add';
        break;
      case 'accept':
        this.icon_name = 'check';
        break;
      case 'decline':
        this.icon_name = 'close';
        break;
      case 'message':
        this.icon_name = 'mail';
        break;
      case 'calendar':
        this.icon_name = 'calendar_today';
        break;
      case 'create':
        this.icon_name = 'add';
        break;
      case 'editCalendar':
        this.icon_name = 'edit_calendar';
        break;
      case 'view':
        this.icon_name = 'visibility';
        break;
      case 'complete':
        this.icon_name = 'check_circle';
        break;
    }

    // Add click event listener to the root element to stop propagation
    this.renderer.listen(this.el.nativeElement, 'click', (event) => {
      event.stopPropagation();
    });
  }

  onClick() {
    if (!this.disabled) {
      this.click.emit();
    }
  }
}
