export class SchoolYear {
	id: number;
	accountId: number;
	name: string;
	startDate: string;
	endDate: string;
	totalDays: number;
	totalHours: number;
	daysOff: number;
	sundayHours: number;
	mondayHours: number;
	tuesdayHours: number;
	wednesdayHours: number;
	thursdayHours: number;
	fridayHours: number;
	saturdayHours: number;
  onSunday: boolean;
  onMonday: boolean;
  onTuesday: boolean;
  onWednesday: boolean;
  onThursday: boolean;
  onFriday: boolean;
  onSaturday: boolean;
	createdBy: number;
	createdAt: string;
  primary: boolean;
  semester2StartDate: string;
  semester1EndDate: string;

  constructor() {
		this.id = -1;
		this.accountId = -1;
		this.name = '';
		this.startDate = '';
		this.endDate = '';
		this.totalDays = 180;
		this.totalHours = -1;
		this.daysOff = -1;
		this.sundayHours = -1;
		this.mondayHours = -1;
		this.tuesdayHours = -1;
		this.wednesdayHours = -1;
		this.thursdayHours = -1;
		this.fridayHours = -1;
		this.saturdayHours = -1;
    this.onSunday = false;
    this.onMonday = true;
    this.onTuesday = true;
    this.onWednesday = true;
    this.onThursday = true;
    this.onFriday = true;
    this.onSaturday = false;
		this.createdBy = -1;
		this.createdAt = '';
    this.primary = false;
    this.semester2StartDate = '';
    this.semester1EndDate = '';
	}
}
