import {Component, EventEmitter, HostBinding, Input, Output} from '@angular/core';
import {v4 as uuid}                                          from "uuid";

@Component({
             selector: 'app-switch',
             templateUrl: './switch.component.html',
             styleUrl: './switch.component.scss'
           })
export class SwitchComponent {
  @Input() value: boolean               = false;
  @Output() valueChange                 = new EventEmitter<boolean>();
  @Input() label: string                = '';
  @HostBinding('style.display') display = 'inline-flex';
  @Input('class') class                 = '';
  @Input() labelSide: 'left' | 'right'  = 'right';
  uniqueId: string                      = uuid();

  onValueChange(value: boolean) {
    this.valueChange.emit(value);
  }
}
