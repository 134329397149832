import {ChangeDetectorRef, Component, inject} from '@angular/core';
import {ConfigService}                        from "../../core/services/config.service";
import {takeUntilDestroyed}                   from "@angular/core/rxjs-interop";
import {filter}                               from "rxjs/operators";
import {AccountService}                       from "../../core/services/db/account.service";
import {Router}                               from "@angular/router";
import {PaymentService}                       from "../../core/services/db/payment.service";
import {interval, switchMap, take}               from "rxjs";
import {AccessCode, PermissionValidationService} from "../../core/services/permission-validation.service";

declare var Chargebee: any;

@Component({
             selector: 'app-subscription-inactive',
             templateUrl: './subscription-inactive.component.html',
             styleUrl: './subscription-inactive.component.scss'
           })
export class SubscriptionInactiveComponent {
  public isPolling = false;
  private accountService = inject(AccountService);
  private paymentService = inject(PaymentService);
  private router         = inject(Router);
  private cdr = inject(ChangeDetectorRef);
  isAdmin: boolean | null = null;
  private permissionService = inject(PermissionValidationService);

  constructor() {
    ConfigService.accountId
      .pipe(
        takeUntilDestroyed(),
        filter(accountId => (accountId !== null && accountId > 0))
      )
      .subscribe(async (accountId) => {
        if (await this.accountService.isActive(accountId!, true)) {
          this.router.navigate(['/']);
        }
        this.isAdmin = await this.permissionService.authorizeAccountPermissions(accountId!, [AccessCode.IsAdmin])
      });
  }

  updateSubscription() {
    const cbInstance = Chargebee.getInstance();
    cbInstance.setPortalSession(() => {
      return this.paymentService.getPortalUrl(ConfigService.accountId.getValue()!)
    });

    let cbPortal = cbInstance.createChargebeePortal();

    cbPortal.open({
                    close: () => {
                      cbInstance.logout();
                      this.pollAccountStatus(ConfigService.accountId.getValue()!);
                    }
                  });
  }

  private pollAccountStatus(accountId: number) {
    this.isPolling = true;
    this.cdr.detectChanges();
    interval(2000).pipe(
      take(10),
      switchMap(() => this.accountService.isActive(accountId, true))
    ).subscribe({
                  next: isActive => {
                    if (isActive) {
                      this.router.navigate(['/']);
                    }
                  },
                  complete: () => {
                    this.isPolling = false;
                  }
                });
  }
}
