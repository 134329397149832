import {inject, Injectable} from '@angular/core';
import {HttpClient}         from "@angular/common/http";
import {ConfigService}      from "../config.service";
import {Transcript}         from "../../models/db/transcript";
import {lastValueFrom}      from "rxjs";
import {ApiInfo}            from "../../models/db/api-info";

@Injectable({
  providedIn: 'root'
})
export class InfoService {
  protected http = inject(HttpClient);

  get url(): string {
    return '/api/info';
  }

  public async get(): Promise<ApiInfo> {
    const response = this.http.get<ApiInfo>(this.url);
    return await lastValueFrom(response);
  }
}
