import {inject, Injectable} from '@angular/core';
import {HttpClient}         from "@angular/common/http";
import {ConfigService}      from "../config.service";
import {lastValueFrom}      from "rxjs";
import {UserClazz}          from "../../models/db/user-clazz";
import {UserClazzUpdate}    from "../../models/db/user-clazz-update";
import {SingleNumber}       from "../../models/db/single-number";

@Injectable({
              providedIn: 'root'
            })
export class UserClazzService {
  protected http = inject(HttpClient);

  getUrl(classId: number): string {
    return '/api/account/' + ConfigService.accountId.getValue() + '/class/' + classId + '/user';
  }

  public async list(classId: number): Promise<UserClazz[]> {
    if (ConfigService.accountId.getValue() == null) {
      return [];
    }
    const response = this.http.get<UserClazz[]>(this.getUrl(classId));
    return await lastValueFrom(response);
  }

  public async update(classId: number, object: UserClazzUpdate[]): Promise<any> {
    const response = this.http.put<object>(this.getUrl(classId), object);
    return await lastValueFrom(response);
  }

  public async overridePercent(classId: number, userId: number, percent: number): Promise<UserClazz> {
    const singleNumber: SingleNumber = {value: percent};
    const response                   = this.http.put<UserClazz>(this.getUrl(classId) + "/" + userId + "/percent", singleNumber);
    return await lastValueFrom(response);
  }

  public getStudents(list: UserClazz[]): number[] {
    return list.filter(x => x.isStudent).map(x => x.userId);
  }

  public getTeachers(list: UserClazz[]): number[] {
    return list.filter(x => x.isTeacher).map(x => x.userId);
  }

  public createUserClazzList(classId: number, studentUserIds: number[], teacherUserIds: number[]): UserClazz[] {
    let userClazzList: UserClazz[] = [];

    for (let userId of studentUserIds) {
      let existingUserClazz = userClazzList.find(x => x.userId === userId);
      if (existingUserClazz) {
        existingUserClazz.isStudent = true;
      } else {
        let userClazz       = new UserClazz();
        userClazz.clazzId   = classId;
        userClazz.userId    = userId;
        userClazz.isStudent = true;
        userClazzList.push(userClazz);
      }
    }

    for (let userId of teacherUserIds) {
      let existingUserClazz = userClazzList.find(x => x.userId === userId);
      if (existingUserClazz) {
        existingUserClazz.isTeacher = true;
      } else {
        let userClazz       = new UserClazz();
        userClazz.clazzId   = classId;
        userClazz.userId    = userId;
        userClazz.isTeacher = true;
        userClazzList.push(userClazz);
      }
    }

    return userClazzList;
  }
}
