import {booleanAttribute, Component, EventEmitter, HostBinding, Input, Output, ViewEncapsulation} from '@angular/core';
import {FormControl, Validators}                                                                  from "@angular/forms";

export type SelectOption = {
  value: any;
  displayText: string;
};

@Component({
             selector: 'app-select',
             templateUrl: './select.component.html',
             styleUrl: './select.component.scss',
             encapsulation: ViewEncapsulation.None
           })
export class SelectComponent {
  @Input() label: string                         = '';
  @Input() options: SelectOption[]               = [];
  @Input() value: any                            = '';
  @Output() valueChange                          = new EventEmitter<any>();
  @Input({transform: booleanAttribute}) required = false;
  @Input('class') class                          = '';
  @Input() disabled                              = false;
  @HostBinding('style.display') display          = 'inline-flex';
  @Input() helpText                              = '';
  @Input() error                                 = false;
  showHelp                                       = false;
  selectControl                                  = new FormControl<any | null>(null);

  onValueChange(value: any) {
    setTimeout(() => {
      this.valueChange.emit(value);
    }, 1);
  }

  ngOnChanges(changes: any) {
    if (changes.required) {
      this.selectControl.setValidators(this.required ? Validators.required : null);
      this.selectControl.updateValueAndValidity();
    }
    if (changes.value) {
      this.selectControl.setValue(this.value);
    }
    if (changes.error && this.error) {
      // Manually setting an error on the control
      this.selectControl.markAsTouched(); // Optionally mark the control as touched to show the error immediately
      this.selectControl.updateValueAndValidity();
    } else if (changes.error && !this.error) {
      this.selectControl.updateValueAndValidity();
    }
  }
}
