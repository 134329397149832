import {APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule} from '@angular/core';
import {BrowserModule}                                                   from '@angular/platform-browser';

import {AppRoutingModule}                                           from './app-routing.module';
import {MainComponent}                                              from './core/components/main/main.component';
import {BrowserAnimationsModule}                                    from '@angular/platform-browser/animations';
import {ServiceWorkerModule}                                        from '@angular/service-worker';
import {DashboardComponent}                                         from './components/dashboard/dashboard.component';
import {ErrorStateMatcher}                                          from '@angular/material/core';
import {FormsModule, ReactiveFormsModule}                           from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientJsonpModule, HttpClientModule} from '@angular/common/http';
import {AuthHttpInterceptor, AuthModule}                            from '@auth0/auth0-angular';
import {environment}                                                from 'src/environments/environment';
import {ServerErrorInterceptor}                                     from './core/interceptors/server-error.interceptor';
import {PageNotFoundComponent}                                      from './components/error/page-not-found/page-not-found.component';
import {AvatarModule}                                               from 'ngx-avatars';
import '@material/web/icon/icon';
import '@material/web/iconbutton/icon-button';
import '@material/web/divider/divider';
import {ErrorPageComponent}                                         from './components/error/error-page/error-page.component';
import {Router}                                                     from '@angular/router';
import * as Sentry                                                  from "@sentry/angular";
import {SharedModule}                                               from "./shared/shared.module";
import {MatMenuModule}                                              from "@angular/material/menu";
import {MatSelectModule}                                            from "@angular/material/select";
import {MatToolbarModule}                                           from "@angular/material/toolbar";
import {MatIconModule}                                              from "@angular/material/icon";
import {DatePipe, DecimalPipe, PercentPipe, TitleCasePipe}          from "@angular/common";
import {ClassSubjectPipe}                                           from "./core/pipes/class-subject.pipe";
import {MatTooltip}                                                 from "@angular/material/tooltip";
import {GradePipe}                                                  from "./core/pipes/grade.pipe";
import {SplashScreenComponent}                                      from './components/splash-screen/splash-screen.component';
import {CdkConnectedOverlay}                                        from "@angular/cdk/overlay";
import {NamePipe}                                                   from "./core/pipes/name.pipe";
import {AddressPipe}                                                from "./core/pipes/address.pipe";
import {TextColorPipe}                                              from "./core/pipes/text-color.pipe";
import {AppComponent}                                               from './app.component';
import {CustomAuthHttpInterceptor}                                  from "./core/interceptors/custom-auth-http.interceptor";
import {AccountTypePipe}                                            from "./core/pipes/account-type.pipe";
import {MatStepperModule}                                           from "@angular/material/stepper";
import {SubscriptionInactiveComponent}                              from './components/subscription-inactive/subscription-inactive.component';

@NgModule({
            schemas: [CUSTOM_ELEMENTS_SCHEMA],
            declarations: [
              MainComponent,
              DashboardComponent,
              PageNotFoundComponent,
              ErrorPageComponent,
              SplashScreenComponent,
              AppComponent,
              SubscriptionInactiveComponent,
            ],
            imports: [
              MatIconModule,
              MatToolbarModule,
              MatSelectModule,
              MatMenuModule,
              MatStepperModule,
              AvatarModule,
              BrowserModule,
              AppRoutingModule,
              BrowserAnimationsModule,
              HttpClientModule,
              HttpClientJsonpModule,
              ReactiveFormsModule,
              FormsModule,
              // Import the module into the application, with configuration
              AuthModule.forRoot({
                                   ...environment.auth0,
                                   httpInterceptor: {
                                     allowedList: [
                                       {
                                         uri: '/api/*'
                                       },
                                     ],
                                   },
                                 }),
              ServiceWorkerModule.register('ngsw-worker.js', {
                enabled: environment.production,
                // Register the ServiceWorker as soon as the application is stable
                // or after 30 seconds (whichever comes first).
                registrationStrategy: 'registerWhenStable:30000',
              }),
              SharedModule,
              MatTooltip,
              CdkConnectedOverlay,
              NamePipe,
              TextColorPipe
            ],
            providers: [
              DatePipe, DecimalPipe, TitleCasePipe,
              ClassSubjectPipe,
              GradePipe, NamePipe,
              AddressPipe,
              TextColorPipe,
              PercentPipe,
              AccountTypePipe,
              {
                provide: HTTP_INTERCEPTORS,
                useClass: CustomAuthHttpInterceptor,
                multi: true,
                deps: [AuthHttpInterceptor],
              },
              AuthHttpInterceptor,
              {
                provide: HTTP_INTERCEPTORS,
                useClass: ServerErrorInterceptor,
                multi: true,
              },
              ErrorStateMatcher,
              {
                provide: ErrorHandler,
                useValue: Sentry.createErrorHandler({
                                                      showDialog: false,
                                                    }),
              }, {
                provide: Sentry.TraceService,
                deps: [Router],
              },
              {
                provide: APP_INITIALIZER,
                useFactory: () => () => {
                },
                deps: [Sentry.TraceService],
                multi: true,
              },
            ],
            bootstrap: [AppComponent],
            exports: []
          })
export class AppModule {
}
