<!--<h2 mat-dialog-title>Delete all elements?</h2>
<mat-dialog-content>This will delete all elements that are currently on this page and cannot be undone.
<app-date-box></app-date-box>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button [mat-dialog-close]="true">Delete</button>
</mat-dialog-actions>-->


<div class="flex flex-wrap gap-4" mat-dialog-title style="display: flex">
  <span style="flex: 1">{{ title }}</span>
  <md-icon-button (click)="onCancel()" *ngIf="allowClose" aria-label="Close dialog" form="form" value="close">
    <md-icon>close</md-icon>
  </md-icon-button>
</div>
<mat-dialog-content>
  <div (keyup.enter)="onSave()" class="gap-4 flex flex-col" id="form" method="dialog" slot="content">
    <ng-container *ngTemplateOutlet="data.contentTemplate"></ng-container>

  </div>
</mat-dialog-content>
@if (save) {
  <mat-dialog-actions class="flex flex-wrap gap-4">
    <div style="flex: 1"></div>
    <app-button type="cancel" (click)="onCancel()" text="Cancel"></app-button>
    <app-button #submit type="save" (click)="onSave()" [text]="submitText" [disabled]="saveDisabled"
                [disableSmallIcon]="submitText!='' && submitText.toLowerCase() != 'save'"></app-button>
  </mat-dialog-actions>
} @else if (data.actionsTemplate) {
  <mat-dialog-actions class="flex flex-wrap gap-4">
    <div style="flex: 1"></div>
    <ng-container *ngTemplateOutlet="data.actionsTemplate"></ng-container>
  </mat-dialog-actions>
}
