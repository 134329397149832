import { Pipe, PipeTransform } from '@angular/core';
import {Address}               from "../models/db/address";

@Pipe({
  name: 'address',
  standalone: true
})
export class AddressPipe implements PipeTransform {

  transform(value: Address, ...args: unknown[]): string {
    if (value) {
      let address = value.line1;
      if (value.line2) {
        address += '\n' + value.line2;
      }
      address += '\n' + value.city + ', ' + value.state + ' ' + value.postalCode;
      return address;
    }
    return '';
  }

}
