import { CUSTOM_ELEMENTS_SCHEMA, Component, Inject, Input } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogModule} from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  standalone: true,
  imports: [MatDialogModule],
  styleUrl: './confirmation.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ConfirmationComponent {
  title: string = "";
  message: string = "";
  confirmText: string = "Confirm";
  cancelText: string = "Cancel";
  constructor(public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: {title: string, message: string, confirmText: string, cancelText: string}) {
      this.title = data.title;
      this.message = data.message;
      this.confirmText = data.confirmText;
      this.cancelText = data.cancelText;
   }
}
