<mat-form-field style="width: 160px">
  <mat-label>{{ label }}</mat-label>
  <input (dateChange)="onValueChange($event.value)"
         [(ngModel)]="_value"
         [matDatepicker]="picker" matInput
  [required]="required"/>
  <mat-hint>MM/DD/YYYY{{required?'*':''}}</mat-hint>
  <mat-datepicker-toggle [for]="picker" matIconSuffix></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
</mat-form-field>
