/// <reference types="@angular/localize" />

import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import * as Sentry              from '@sentry/angular';

import {AppModule}   from './app/app.module';
import {environment} from './environments/environment';

if (environment.name !== 'local') {
  Sentry.init({
    dsn: 'https://82122ccc09804d868e64dde6dd86ee58@o4505482734862336.ingest.sentry.io/4506679491362816',
    environment: environment.name,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    tracePropagationTargets: environment.sentry.tracePropagationTargets,
    // Performance Monitoring
    tracesSampleRate: environment.sentry.tracesSampleRate,
    // Session Replay
    replaysSessionSampleRate: environment.sentry.replaysSessionSampleRate,
    replaysOnErrorSampleRate: environment.sentry.replaysOnErrorSampleRate,
  });
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
