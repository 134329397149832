import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationComponent } from 'src/app/shared/confirmation/confirmation.component';

@Injectable({
  providedIn: 'root'
})
export class ConfirmationService {

  constructor(public dialog: MatDialog) { }

  public confirm(title: string, message: string, confirmText: string, cancelText: string, confirm: Function, cancel: Function) {
    const ref = this.dialog.open(ConfirmationComponent, { data: { title: title, message: message, confirmText: confirmText, cancelText: cancelText } });

    ref.afterClosed().subscribe(result => {
      if (result) {
        confirm();
      } else {
        cancel();
      }
    });
  }
}
