import {Pipe, PipeTransform} from '@angular/core';
import {AccountType}         from "../models/db/account";

@Pipe({
        name: 'accountType',
        standalone: true
      })
export class AccountTypePipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    let se = Object.entries(AccountType).find((key) => key[0] == value);
    if (se) {
      return se[1];
    } else {
      return '';
    }
  }

}
