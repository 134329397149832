export interface GradeYear {
  grade: number;
  year: string;
}

export class User {
  id: number;
  studentId: number;
  birthDate: string;
  auth0Id: string;
  firstName: string;
  lastName: string;
  nickName: string;
  email: string;
  isSuperuser: boolean;
  defaultAccountId: number;
  type: string;
  phone: string;
  graduationDate: string;
  gpa: number;
  weightedGpa: number;
  transcriptNote: string;
  color: string;
  dailyDigestTime: any;
  weeklyDigestTime: any;
  dailyDigestEnable: boolean;
  weeklyDigestEnable: boolean;
  profileUrl: string;
  profileThumbnailUrl: string;
  billingRef: string;
  inviteSentDt: Date | null;
  inviteAcceptedDt: Date | null;
  isStudent: boolean;

  constructor() {
    this.id                  = -1;
    this.studentId           = -1;
    this.birthDate           = '';
    this.auth0Id             = '';
    this.firstName           = '';
    this.lastName            = '';
    this.nickName            = '';
    this.email               = '';
    this.isSuperuser         = false;
    this.defaultAccountId    = -1;
    this.type                = 'student';
    this.phone               = '';
    this.graduationDate      = '';
    this.gpa                 = 0;
    this.weightedGpa         = 0;
    this.transcriptNote      = '';
    const colors             = ['#FDAC53', '#9BB7D4', '#B55A30', '#F5DF4D', '#0072B5', '#A0DAA9', '#E9897E', '#00A170', '#926AA6', '#D2386C'];
    this.color               = colors[Math.floor(Math.random() * colors.length)];
    this.dailyDigestTime     = '07:00';
    this.weeklyDigestTime    = '18:00';
    this.dailyDigestEnable   = true;
    this.weeklyDigestEnable  = true;
    this.profileUrl          = '';
    this.profileThumbnailUrl = '';
    this.billingRef          = '';
    this.inviteSentDt        = null
    this.inviteAcceptedDt    = null;
    this.isStudent           = true;
  }
}
