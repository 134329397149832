<section class="bg-white dark:bg-gray-900 ">
    <div class="container px-6 py-12 mx-auto lg:flex lg:items-center lg:gap-12">
        <div class="w-full lg:w-1/2">
            <p class="text-sm font-medium text-blue-500 dark:text-blue-400">{{errorCode}} error</p>
            <h1 class="mt-3 text-2xl font-semibold text-gray-800 dark:text-white md:text-3xl">{{errorMessage}}</h1>
            <p class="mt-4 text-gray-500 dark:text-gray-400">Sorry, there was an error processing what you were doing :(  <br/><br/>Here are some helpful links:</p>

            <div class="flex items-center mt-6 gap-x-3">
              <app-button type="back" text="Go back" (click)="location.back()"></app-button>
              <app-button type="home" text="Take me home" (click)="reloadHome()"></app-button>
            </div>
        </div>

        <div class="relative w-full mt-12 lg:w-1/2 lg:mt-0">
            <img class="w-full max-w-lg lg:mx-auto" src="{{illustrationUrl}}" alt="">
        </div>
    </div>
</section>
