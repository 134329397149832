<app-page title="Account is Inactive">
  <div class="flex flex-col gap-4">
    <span>Your account is currently inactive.</span>
    @if (isAdmin) {
      <div>
        Please click here to update your subscription.
      </div>
      <app-button type="payments" text="Subscription" (click)="updateSubscription()"></app-button>
    } @else if (isAdmin !== null && !isAdmin) {
      <div>
        Please contact your administrator to update your subscription.
      </div>
    }
    <app-spinner *ngIf="isPolling" class="w-32"></app-spinner>
    <div *nfIf="isPolling">Checking</div>
  </div>
</app-page>
<!--
<app-dialog>
  <ng-template>
    <div class="pb-5">
      Your account is inactive. Please click here to update your subscription.
    </div>
  </ng-template>
</app-dialog>
-->
