import {inject, Injectable} from '@angular/core';
import {HttpClient}         from "@angular/common/http";
import {lastValueFrom}      from "rxjs";
import {CourseRequest}      from "../../models/db/course";
import {SingleValue}        from "../../models/db/single-value";

@Injectable({
              providedIn: 'root'
            })
export class EmailService {
  protected http = inject(HttpClient);

  constructor() {
  }

  get url(): string {
    return '/api/email';
  }

  public async emailCourseRequest(course: CourseRequest) {
    const response = this.http.post(this.url + '/course-request', course);
    return await lastValueFrom(response);
  }

  public async emailSuggestion(message: string) {
    const response = this.http.post(this.url + '/suggestion', new SingleValue(message));
    return await lastValueFrom(response);
  }
}
