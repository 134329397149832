import {Component, inject}      from '@angular/core';
import {HttpClient}             from "@angular/common/http";
import {SingleValue}            from "../../../core/models/db/single-value";
import {ActivatedRoute, Router} from "@angular/router";
import {take}                   from "rxjs";

@Component({
             selector: 'app-canny',
             templateUrl: './canny.component.html',
             styleUrl: './canny.component.scss'
           })
export class CannyComponent {
  http   = inject(HttpClient);
  router = inject(Router);
  route  = inject(ActivatedRoute);

  ngOnInit() {
    this.route.queryParams
      .pipe(take(1))
      .subscribe(params => {
        const redirectUrl = params['redirect'];
        const companyId   = params['companyID'];

        this.http.get<SingleValue>('/api/sso/canny')
          .pipe(take(1))
          .subscribe((response) => {
            // Redirect the user to the redirectUrl
            window.location.href = "https://canny.io/api/redirects/sso?redirect=" + redirectUrl + '&ssoToken=' + response.value + '&companyID=' + companyId;
          });
      });
  }
}
