import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'grade',
  standalone: true
})
export class GradePipe implements PipeTransform {

  transform(value: number, ...args: unknown[]): string {
    switch(value){
      case -1:
      return "Pre-K";
      case 0:
      return "Kindergarten";
      case 1:
      return "1st Grade";
      case 2:
      return "2nd Grade";
      case 3:
      return "3rd Grade";
      case 4:
      return "4th Grade";
      case 5:
      return "5th Grade";
      case 6:
      return "6th Grade";
      case 7:
      return "7th Grade";
      case 8:
      return "8th Grade";
      case 9:
      return "9th Grade";
      case 10:
      return "10th Grade";
      case 11:
      return "11th Grade";
      case 12:
      return "12th Grade";
      default:
        return "";
    }
  }

}
