import { Component, Input } from '@angular/core';
import { Location } from '@angular/common';
import {Observable}         from "rxjs";

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrl: './page.component.scss'
})
export class PageComponent {
  @Input() title: string = '';
  @Input() details: string = '';
  @Input() showBack: boolean = false;
  @Input() new: (() => void) | null = null;
  @Input() save: (() => void) | null = null;
  @Input() disableSave = false;
  @Input() delete: (() => void) | null = null;
  @Input() helpUrl = '';
  @Input() saving: Observable<boolean> | null     = null;
  constructor(private _location: Location) {
  }

  back() {
    this._location.back();
  }

  openHelpUrl() {
    if (this.helpUrl) {
      window.open(this.helpUrl, '_blank');
    }
  }
}
