import {Role} from "./role";
import {UserAccountAccess} from "./user-account-access";

export class UserAccount {
  id: number;
  userId: number;
  accountId: number;
  isAdmin: boolean;
  status: string;
  role: Role | null;
  userAccountAccesses: UserAccountAccess[];
  createdAt: string;
  inviteSentDt: string
  inviteAcceptedDt: string;

	constructor() {
		this.id = -1;
		this.userId = -1;
		this.accountId = -1;
		this.isAdmin = false;
		this.status = '';
		this.role = null;
    this.userAccountAccesses = [];
    this.createdAt='';
    this.inviteSentDt ='';
    this.inviteAcceptedDt ='';
	}
}
