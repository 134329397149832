import { Component } from '@angular/core';

@Component({
  selector: 'app-feature-request',
  templateUrl: './feature-request.component.html',
  styleUrl: './feature-request.component.scss'
})
export class FeatureRequestComponent {
  url = 'https://homeschool-anchor.canny.io/feature-requests';

  onClick(){
    window.open(this.url, '_blank');
  }
}
