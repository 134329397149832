import {Directive, EventEmitter, HostListener, Output} from '@angular/core';

@Directive({
  selector: '[appBlur]'
})
export class BlurDirective {
  @Output() appBlur: EventEmitter<void> = new EventEmitter<void>();

  @HostListener('blur')
  onBlur() {
    this.appBlur.emit();
  }

  @HostListener('keydown.enter')
  onEnter(){
    this.appBlur.emit();
  }
}
