import {Component, ContentChild, ElementRef, EventEmitter, inject, Input, Output, TemplateRef, ViewChild} from '@angular/core';
import {MatDialog, MatDialogConfig, MatDialogRef}                                                         from "@angular/material/dialog";
import {MatDialogComponent}                                                                               from "../mat-dialog/mat-dialog.component";

@Component({
             selector: 'app-dialog',
             templateUrl: './dialog.component.html',
             styleUrl: './dialog.component.scss',
           })
export class DialogComponent {
  /**
   * Title of the dialog
   */
  @Input() title: string                    = '';
  @Input() save: (() => void) | null        = null;
  @Input() saveDisabled: boolean            = false;
  @Output() close: EventEmitter<any>        = new EventEmitter();
  @Input() submitText: string               = 'Save';
  @Input() validate: (() => boolean) | null = null;
  @ViewChild('dialog') mdDialog!: ElementRef;
  @Input() allowClose                       = true;
  @Input() class                            = '';
  _class                                    = ""
  @ContentChild(TemplateRef) contentTemplate!: TemplateRef<any>;
  @ContentChild('actions') actionsTemplate!: TemplateRef<any>;

  dialog = inject(MatDialog);
  dialogRef: MatDialogRef<MatDialogComponent> | null = null;

  ngOnChanges(changes: any) {
    if (changes.class) {
      this._class = "w-[90vh] md:w-1/2 " + this.class;
    }
  }

  ngOnInit() {
    this._class = "w-[90vh] md:w-1/2 " + this.class;
  }

  ngAfterContentInit() {
    setTimeout(() => {
      const dialogConfig        = new MatDialogConfig();
      dialogConfig.data         = {
        title: this.title,
        save: this.save,
        saveDisabled: this.saveDisabled,
        submitText: this.submitText,
        validate: this.validate,
        allowClose: this.allowClose,
        class: this.class,
        contentTemplate: this.contentTemplate,
        actionsTemplate: this.actionsTemplate,
      };
      dialogConfig.panelClass   = this._class.split(" ");
      dialogConfig.disableClose = !this.allowClose;
      this.dialogRef            = this.dialog.open(MatDialogComponent, dialogConfig);
      this.dialogRef.afterClosed().subscribe(result => {
        this.onClose();
      });
    }, 1);
  }

  ngOnDestroy() {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

  onClose() {
    this.close.emit();
  }
}
