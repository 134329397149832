import {inject, Injectable} from '@angular/core';
import {HttpClient}         from "@angular/common/http";
import {ConfigService}      from "../config.service";
import {HomeschoolSetup}      from "../../models/db/homeschool-setup";
import {firstValueFrom, take} from "rxjs";

@Injectable({
              providedIn: 'root'
            })
export class AccountSetupService {
  private http: HttpClient = inject(HttpClient);

  constructor() {
  }

  public async setupHomeschool(setup: HomeschoolSetup): Promise<void> {
    if (ConfigService.accountId.getValue() == null) {
      return;
    }
    const resp = this.http.post(
      '/api/account/' + ConfigService.accountId.getValue() + '/setup/homeschool',
      setup
    );
    await firstValueFrom(resp);
    return;
  }
}
