export class HomeschoolSetup {
  startDate: string = '';
  studentFirstName: string = '';
  studentLastName: string = '';
  studentBirthDate: string = '';
  studentGrade: number = -1;
  studentEmail: string = '';
  onSunday: boolean = false;
  onMonday: boolean = true;
  onTuesday: boolean = true;
  onWednesday: boolean = true;
  onThursday: boolean = true;
  onFriday: boolean = true;
  onSaturday: boolean = false;
}
