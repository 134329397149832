import {Inject, Injectable}                                                                       from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpStatusCode,} from '@angular/common/http';
import {Observable, throwError}                                                                   from 'rxjs';
import {catchError}                                                                               from 'rxjs/operators';
import {NotificationService}                                                                      from '../services/notification.service';
import {Router}                                                                                   from '@angular/router';
import {AuthService}                                                                              from '@auth0/auth0-angular';
import {DOCUMENT}                                                                                 from '@angular/common';

@Injectable()
export class ServerErrorInterceptor implements HttpInterceptor {
  constructor(
    @Inject(DOCUMENT) public document: Document,
    private alert: NotificationService,
    private router: Router,
    public auth: AuthService
  ) {
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      // retry(1), Commenting this out on 8/26/2024.  I don't believe we should do a retry on an error, and I'm not sure why this is here
      catchError((error: HttpErrorResponse) => {
        switch (error.status) {
          case 0:
            this.alert.error('Connection issue or server did not send a response. Possible Payload Too Large error.');
            break;
          case HttpStatusCode.GatewayTimeout:
            console.log(error);
            setTimeout(() => {
              this.router.navigate(['/error'], {queryParams: {errorCode: error.status, errorMessage: error.statusText}});
            }, 500);
            break;
          case HttpStatusCode.Unauthorized:
            this.alert.error('Log in');
            this.auth.logout({
                               logoutParams: {
                                 returnTo: this.document.location.origin,
                               },
                             });
            break;
          case HttpStatusCode.Forbidden:
            if (
              error.error?.detail &&
              error.error?.detail.length > 0 &&
              error.error.detail[0].msg
            ) {
              this.alert.error(error.error.detail[0].msg);
            } else if (error.error?.detail && error.error?.detail.length > 0) {
              this.alert.error(error.error.detail);
            } else {
              this.alert.error('You do not have permission to access this resource.');
            }
            break;
          case HttpStatusCode.PayloadTooLarge:
            this.alert.error('The file is too large.');
            break;
          default:
            if (error.error?.message) {
              this.alert.error(error.error.message);
            } else if (
              error.error?.detail &&
              error.error?.detail.length > 0 &&
              error.error.detail[0].msg
            ) {
              this.alert.error(error.error.detail[0].msg);
            } else if (error.error?.detail && error.error?.detail.length > 0) {
              this.alert.error(error.error.detail);
            } else if (error.error?.error) {
              this.alert.error(error.error.error);
            } else {
              this.alert.error(error.error + '\n' + error.url);
            }
        }
        throwError(() => new Error(JSON.stringify(error)));
        //console.log(JSON.stringify(error));
        return throwError(() => error);
      })
    );
  }
}
