import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-help-link',
  templateUrl: './help-link.component.html',
  styleUrl: './help-link.component.scss'
})
export class HelpLinkComponent {
  @Input() link: string = '';
  @Input() label: string = 'Need Help?';
  @Input() iconOnly = false;

  openLink() {
    window.open(this.link, '_blank');
  }
}
