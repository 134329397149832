import { Pipe, PipeTransform } from '@angular/core';
import {SubjectEnum}           from "../models/db/clazz";

@Pipe({
  name: 'classSubject',
  standalone: true
})
export class ClassSubjectPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): string {
    let se = Object.entries(SubjectEnum).find((key) => key[0] == value);
    if (se) {
      return se[1];
    } else {
      return '';
    }
  }

}
