<div (click)="$event.stopPropagation()" class="w-full h-full">
  @if (disableSmallIcon) {
    <div>
      <md-filled-button (click)="onClick(); $event.stopPropagation()" [disabled]="disabled">
        <div class="flex flex-row gap-2">
          @if (_working) {
            <div class="relative min-w-4">
              <ngx-spinner color="#023047" bdColor="rgba(0,0,0,0)" type="ball-spin" [fullScreen]="false"
                           size="small"></ngx-spinner>
            </div>
          } @else {
            <md-icon>{{ icon_name }}</md-icon>
          }
          <span>
          {{ text }}
            </span>
        </div>
      </md-filled-button>
    </div>
  } @else if (iconOnly == false) {
    <div class="hidden md:inline">
      <md-filled-button (click)="onClick(); $event.stopPropagation()" [disabled]="disabled"
                        [trailingIcon]="type=='next'">
        <div class="flex flex-row gap-2">
          @if (_working) {
            <div class="relative w-4">
              <ngx-spinner color="#023047" bdColor="rgba(0,0,0,0)" type="ball-spin" [fullScreen]="false"
                           size="small"></ngx-spinner>
            </div>
          } @else {
            <md-icon>{{ icon_name }}</md-icon>
          }
          <span>
          {{ text }}
            </span>
        </div>
      </md-filled-button>
    </div>
    <div class="md:hidden">
      <md-filled-icon-button (click)="onClick(); $event.stopPropagation()" [disabled]="disabled">
        @if (_working) {
          <div class="relative w-4">
            <ngx-spinner color="#FFFFFF" bdColor="rgba(0,0,0,0)" type="ball-spin" [fullScreen]="false"
                         size="small"></ngx-spinner>
          </div>
        } @else {
          <md-icon>{{ icon_name }}</md-icon>
        }
      </md-filled-icon-button>
    </div>
  } @else {
    <div>
      <md-filled-icon-button (click)="onClick(); $event.stopPropagation()" [disabled]="disabled">
        @if (_working) {
          <div class="relative w-4">
            <ngx-spinner color="#FFFFFF" bdColor="rgba(0,0,0,0)" type="ball-spin" [fullScreen]="false"
                         size="small"></ngx-spinner>
          </div>
        } @else {
          <md-icon>{{ icon_name }}</md-icon>
        }
      </md-filled-icon-button>
    </div>
  }
</div>
