import {inject, Injectable} from '@angular/core';
import {HttpClient}         from "@angular/common/http";
import {ConfigService}      from "../config.service";
import {LessonPlan}         from "../../models/db/lesson-plan";
import {lastValueFrom}      from "rxjs";
import {SingleValue}        from "../../models/db/single-value";
import {SubscriptionPlan}   from "../../models/db/subscription-plan";

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  private http = inject(HttpClient);
  getUrl(accountId: number): string {
    return '/api/account/' + accountId + '/billing';
  }
  constructor() { }

  async getPortalUrl(accountId: number): Promise<any> {
    if (accountId == null || accountId <= 0) {
      return '';
    }
    const response = this.http.get<any>(this.getUrl(accountId)+"/portal");
    const sv = await lastValueFrom(response);
    return sv;// sv.value!;
  }

  async getPlans(accountId: number): Promise<SubscriptionPlan[]> {
    if (accountId == null || accountId <= 0) {
      return [];
    }
    const response = this.http.get<SubscriptionPlan[]>(this.getUrl(accountId)+"/plans");
    return await lastValueFrom(response);
  }

  async subscribe(accountId: number, planId: string): Promise<any> {
    if (accountId == null || accountId <= 0) {
      return '';
    }
    const response = this.http.get<any>(this.getUrl(accountId)+"/subscribe/"+ planId);
    return await lastValueFrom(response);
  }
}
