import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorIllustrationService {

  private illustrations: { [key: string]: string } = {
    '404': '/assets/img/error/404.png',
    '504': '/assets/img/error/504.png',
    '401': '/assets/img/error/501.png',
    '400': '/assets/img/error/400.png',
    '418': '/assets/img/error/418.png',
    '301': '/assets/img/error/301.png',
    '503': '/assets/img/error/503.png',
    '500': '/assets/img/error/500.png',
  };

  constructor() { }

  getIllustration(errorCode: string): string {
    return this.illustrations[errorCode] || '/assets/img/error/unknown.png';
  }
}