import {Component, OnInit}        from '@angular/core';
import {ActivatedRoute}           from '@angular/router';
import {Location}                 from '@angular/common';
import {ErrorIllustrationService} from 'src/app/core/services/error-illustration.service';

@Component({
             selector: 'app-error-page',
             templateUrl: './error-page.component.html',
             styleUrls: ['./error-page.component.scss'],
           })
export class ErrorPageComponent implements OnInit {
  errorCode: string;
  errorMessage: string;
  illustrationUrl: string;

  constructor(
    private route: ActivatedRoute,
    public location: Location,
    private illustrationService: ErrorIllustrationService
  ) {
    this.errorCode       = '';
    this.errorMessage    = '';
    this.illustrationUrl = '';
  }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.errorCode       = params['errorCode'];
      this.errorMessage    = params['errorMessage'];
      this.illustrationUrl = this.illustrationService.getIllustration(this.errorCode);
    });
  }

  reloadHome() {
    window.location.href = '/';
  }
}
