import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'textColor',
  standalone: true
})
export class TextColorPipe implements PipeTransform {

  transform(backgroundColor: string, ...args: unknown[]): string {
    if (!backgroundColor || backgroundColor.length < 1) {
      return 'black';
    }

    // Convert hex color to RGB
    const rgb = parseInt(backgroundColor.slice(1), 16);   // strip # and convert to integer
    const r = (rgb >> 16) & 0xff;  // extract red
    const g = (rgb >>  8) & 0xff;  // extract green
    const b = (rgb >>  0) & 0xff;  // extract blue

    // Calculate brightness of the color
    const brightness = (r * 299 + g * 587 + b * 114) / 1000;

    // Return white for dark colors and black for light colors
    // This is a range of 0-255, with 0 being black and 255 being white
    return brightness > 165 ? 'var(--color-primary)' : 'var(--color-secondary)';
  }

}
