import {Component, ElementRef, inject, Inject, Renderer2, ViewChild} from '@angular/core';
import {DOCUMENT}                                                    from "@angular/common";

@Component({
  selector: 'app-splash-screen',
  templateUrl: './splash-screen.component.html',
  styleUrl: './splash-screen.component.scss'
})
export class SplashScreenComponent {

  @ViewChild('splash') overlay!: ElementRef;
  private renderer: Renderer2 = inject(Renderer2);
  @Inject(DOCUMENT) private document: Document = inject(DOCUMENT);

  ngAfterViewInit() {
    this.renderer.appendChild(this.document.body, this.overlay.nativeElement);
  }

  ngOnDestroy() {
    this.renderer.removeChild(this.document.body, this.overlay.nativeElement);
  }
}
