import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthHttpInterceptor } from '@auth0/auth0-angular';

@Injectable()
export class CustomAuthHttpInterceptor implements HttpInterceptor {
  constructor(private authInterceptor: AuthHttpInterceptor) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // List of endpoints to exclude from authentication
    const excludedEndpoints = ['/api/billing/subscription', '/api/another-endpoint-to-exclude'];

    // Check if the request URL matches any of the excluded endpoints
    if (!excludedEndpoints.some(endpoint => req.url.includes(endpoint))) {
      // If the request URL does not match any of the excluded endpoints, use the AuthHttpInterceptor
      return this.authInterceptor.intercept(req, next);
    }

    // If the request URL matches one of the excluded endpoints, bypass the AuthHttpInterceptor
    return next.handle(req);
  }
}
