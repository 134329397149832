import {Component, EventEmitter, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef}   from "@angular/material/dialog";

@Component({
             templateUrl: './mat-dialog.component.html',
             styleUrl: './mat-dialog.component.scss'
           })
export class MatDialogComponent {
  title: string                    = '';
  save: (() => void) | null        = null;
  saveDisabled: boolean            = false;
  close: EventEmitter<any>         = new EventEmitter();
  submitText: string               = 'Save';
  validate: (() => boolean) | null = null;
  allowClose                       = true;
  class                            = '';

  constructor(public dialogRef: MatDialogRef<MatDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.title        = data.title;
    this.save         = data.save;
    this.saveDisabled = data.saveDisabled;
    this.submitText   = data.submitText;
    this.validate     = data.validate;
    this.allowClose   = data.allowClose;
    this.class        = data.class;
  }

  onClose() {
    this.close.emit();
  }

  onSave() {
    if (this.save) {
      if (this.validate && !this.validate()) {
        return;
      }
      this.dialogRef.close();
      this.save();
    }
  }

  onCancel() {
    this.dialogRef.close();
  }
}
