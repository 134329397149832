import {Component, EventEmitter, inject, Input, Output} from '@angular/core';
import {animate, state, style, transition, trigger}     from "@angular/animations";
import {TextColorPipe}                              from "../../core/pipes/text-color.pipe";

@Component({
             selector: 'app-card',
             templateUrl: './card.component.html',
             styleUrl: './card.component.scss',
             animations: [
               trigger('detailExpand', [
                 state('collapsed,void', style({height: '0px', minHeight: '0', padding: '0rem'})),
                 state('expanded', style({height: '*', padding: '1rem'})),
                 transition(
                   'expanded <=> collapsed',
                   animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
                 ),
               ]),
             ],
           })
export class CardComponent {
  @Input() thumbnailUrl: string = "";
  @Input() title: string        = "";
  @Input() subtitle: string     = "";
  @Input() expandable: boolean  = true;
  @Input() expanded: boolean    = true;
  @Output() expandedChange      = new EventEmitter<boolean>();
  @Input() isLoading: boolean   = false;
  @Input() margin               = "m-4"
  @Input() backgroundColor      = '';
  @Input() helpUrl              = '';
  @Input() highlight = false;
  textColor: string             = '';
  textColorPipe                 = inject(TextColorPipe);
  _margin                       = this.margin;

  get _backgroundColor(): string | null{
    if (this.highlight){
      return '#FFFFFF';
    } else if (this.backgroundColor){
      return this.backgroundColor;
    } else {
      return null
    }
  }
  get _textColor(): string | null {
    if (this.highlight){
      return '#669BBC'; //TODO - this should be a variable
    } else if (this.textColor) {
      return this.textColor;
    } else {
      return null;
    }
  }

  ngOnChanges(changes: any) {
    if (changes.backgroundColor) {
      this.textColor = this.textColorPipe.transform(this.backgroundColor);
    }
  }

  openHelpUrl() {
    if (this.helpUrl) {
      window.open(this.helpUrl, '_blank');
    }
  }
}
