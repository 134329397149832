import {inject, Injectable}                              from '@angular/core';
import {Account, AccountType}                            from '../models/db/account';
import {BehaviorSubject, firstValueFrom, Observable, of} from 'rxjs';
import {ActivatedRouteSnapshot, NavigationEnd, Router,}  from '@angular/router';
import {UserAccount}                                     from "../models/db/user-account";
import {filter, map}                                     from "rxjs/operators";
import {User}                                            from "../models/db/user";
import {AccountService}                                  from "./db/account.service";


@Injectable({
              providedIn: 'root',
            })
export class ConfigService {
  public static accountId                               = new BehaviorSubject<number | null>(null);
  public static user                                    = new User();
  private static pathArray: Array<string | undefined>   = [];
  private static accountTypes: Map<number, AccountType> = new Map();
  private accountService                                = inject(AccountService);

  constructor(
    private router: Router,
  ) {
    // This will always keep current state of the route
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        ConfigService.pathArray = this.getPathArray(
          this.router.routerState.snapshot.root
        );
      }
    });
  }

  public static _userAccounts = new BehaviorSubject<UserAccount[]>([]);

  public static get userAccounts(): Observable<UserAccount[]> {
    if (this._userAccounts.getValue().length > 0) {
      return of(this._userAccounts.getValue());
    } else {
      return this._userAccounts.asObservable().pipe(
        filter((accounts) => accounts && accounts.length > 0)
      );
    }
  }

  public static set userAccounts(uas: UserAccount[]) {
    this._userAccounts.next(uas);
  }

  public static setAccountType(accountId: number, type: AccountType) {
    this.accountTypes.set(accountId, type);
  }

  /**
   * This gets the path with the  account id replaced
   * @param id account id to update the path with
   * @returns A navigatable url for routing - ie this.router.navigate(path)
   */
  public static replacePathAccountId(id: number) {
    // Get the actual URL
    const actualUrl = window.location.pathname;

    // Split the actual URL into its components
    const actualUrlParts = actualUrl.split('/').splice(1);

    // Path array may look like ['account', ':accountId', 'transcripts'] or ['account/45/class','9']
    // So we need to split the parts into an array of strings
    let routePath: string[] = [];
    ConfigService.pathArray.forEach(part => {
      if (part?.indexOf('/')) {
        part.split('/').forEach(str => routePath.push(str));
      } else {
        routePath.push(part || '');
      }
    })

    // Map over the pathArray and replace :accountId with the new value
    // Join the parts back together into a URL
    let parts = routePath.map((part, index) => {
      if (part === ':accountId') {
        return String(id);
      } else {
        // If it's not the accountId, return the corresponding part from the actual URL
        return actualUrlParts[index];
      }
    });
    return parts;
  }

  public static async getCurrentUserAccount(): Promise<UserAccount> {
    const accountId = await firstValueFrom(ConfigService.accountId.pipe(
      filter(accountId => accountId !== null && accountId > 0)
    ));
    if (accountId === null) {
      throw new Error('Account ID is null');
    }

    return await firstValueFrom(ConfigService._userAccounts.pipe(
      filter(userAccounts => userAccounts && userAccounts.length > 0),
      map(userAccounts => {
        const userAccount = userAccounts.find(ua => ua.accountId === accountId);
        return userAccount || new UserAccount();
      })
    ));
  }

  public async getAccountType(accountId: number): Promise<AccountType> {
    let accountType = AccountType.home;
    if (ConfigService.accountTypes.has(accountId)) {
      accountType = ConfigService.accountTypes.get(accountId)!;
    } else {
      const account = await this.accountService.get(accountId);
      ConfigService.accountTypes.set(accountId, account.type);
      accountType = account.type;
    }
    const accountTypeKey = accountType as unknown as keyof typeof AccountType;
    return AccountType[accountTypeKey];
  }

  public async getAccountStatus(accountId: number): Promise<AccountType> {
    if (ConfigService.accountTypes.has(accountId)) {
      return ConfigService.accountTypes.get(accountId)!;
    } else {
      const account = await this.accountService.get(accountId);
      ConfigService.accountTypes.set(accountId, account.type);
      return account.type;
    }
  }

  /**
   * Gets an array of the elements of the route
   * @param route
   * @returns
   */
  getPathArray(route: ActivatedRouteSnapshot): Array<string | undefined> {
    //TODO This appears to be a recursive function that gets the path of the route, but it appears to always return back a single element array
    let array: Array<string | undefined> = [];

    if (route.routeConfig && route.routeConfig.path !== '') {
      array.push(route.routeConfig.path);
    }

    if (route.firstChild) {
      array = array.concat(this.getPathArray(route.firstChild));
    }

    // When the route changes, pull values from it, and set the state
    if (route.params['accountId']) {
      this.setSelectedAccountId(route.params['accountId']);
    }

    return array;
  }

  //}
  setSelectedAccountId(accountId: number) {
    accountId = Number(accountId);
    if (
      Number.isInteger(accountId) &&
      ConfigService.accountId.getValue() != accountId
    ) {
      ConfigService.accountId.next(accountId);
      sessionStorage.setItem('accountId', String(accountId));
    }
  }

  getSelectedAccountId(): number | null {
    return ConfigService.accountId.getValue();
  }

  setSelectedAccount(account: Account) {
    this.setSelectedAccountId(account.id);
  }
}
