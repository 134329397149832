<mat-sidenav-container
  [class.isMobile]="mobileQuery.matches"
  class="sidenav-container h-full flex flex-grow flex-shrink auto sm:flex-1">
  <mat-sidenav
    #snav
    [mode]="mobileQuery.matches ? 'over' : 'side'"
    [opened]="true"
    [disableClose]="false">
    <aside aria-label="Sidenav"
           class="z-40 w-60 md:w-64 h-full"
           id="default-sidebar">
      <div
        class="space-y-2 overflow-y-auto py-5 px-3 h-full bg-white border-r border-gray-200 dark:bg-gray-800 dark:border-gray-700 flex flex-col">
        <ul class="space-y-2">
          @for (item of menuItems; track $index) {
            <li>
              @if (item.divider) {
                <md-divider></md-divider>
                <h3 mat-subheader>{{ item.name }}</h3>
              }

              <a [routerLink]="item.link" *ngIf="!item.divider && (!item.children || item.children.length == 0)" (click)="mobileQuery.matches ? snav.toggle():{}"
                 class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                 [ngClass]="{'bg-gray-100': activeLinks[item.link]}">
                <md-icon mat-list-icon>{{ item.icon }}</md-icon>
                <span class="ml-3">{{ item.name }}</span>
              </a>

              @if (item.children && item.children.length > 0) {
                <button type="button"
                        class="flex items-center p-2 w-full text-base font-normal text-gray-900 rounded-lg transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                        data-collapse-toggle="dropdown-Settings">
                  <md-icon mat-list-icon>{{ item.icon }}</md-icon>
                  <!-- <md-icon mat-list-icon>{{ item.icon }}</md-icon>-->
                  <span class="flex-1 ml-3 text-left whitespace-nowrap">{{ item.name }}</span>
                  <svg aria-hidden="true" class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20"
                       xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clip-rule="evenodd"></path>
                  </svg>
                </button>
                <ul id="dropdown-{{item.name}}" class="hidden py-2 space-y-2">
                  @for (child of item.children; track $index) {
                    <li>
                      <a [routerLink]="child.link"
                         (click)="mobileQuery.matches ? snav.toggle():{}"
                         class="flex items-center p-2 pl-11 w-full text-base font-normal text-gray-900 rounded-lg transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                         [ngClass]="{'bg-gray-100': activeLinks[child.link]}"
                      >{{ child.name }}</a>
                    </li>
                  }
                </ul>
              }
            </li>
          }
        </ul>
        <ng-content></ng-content>
        <div class="flex flex-col justify-end flex-grow text-gray-400 text-sm"><span *ngIf="apiInfo">Ver: {{ version() }}</span></div>
      </div>
    </aside>

  </mat-sidenav>
  <mat-sidenav-content>
    <div class="m-4 md:m-6">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
