import {Injectable}                from '@angular/core';
import {NotificationService}       from './notification.service';
import {HttpClient}                from '@angular/common/http';
import {lastValueFrom, Observable} from 'rxjs';
import {ConfigService}             from './config.service';
import {AssignmentType}            from '../models/db/assignment-type';
import {UserDetailed}              from "../models/db/user-detailed";

@Injectable({
              providedIn: 'root',
            })
export class DatabaseService {
  constructor(
    private http: HttpClient,
  ) {
  }

  public async getAssignmentTypes(): Promise<AssignmentType[]> {
    if (ConfigService.accountId.getValue() == null) {
      return []
    }
    const response = this.http.get<any>('/api/account/' + ConfigService.accountId.getValue() + '/assignmentType');
    return await lastValueFrom(response);
  }

  public async createAssignmentType(assignmentType: AssignmentType): Promise<AssignmentType> {
    const response = this.http.post<AssignmentType>('/api/account/' + ConfigService.accountId.getValue() + '/assignmentType', assignmentType);
    return await lastValueFrom(response);
  }

  public async updateAssignmentType(assignmentType: AssignmentType): Promise<AssignmentType> {
    const response = this.http.put<AssignmentType>('/api/account/' + ConfigService.accountId.getValue() + '/assignmentType/' + assignmentType.id, assignmentType);
    return await lastValueFrom(response);
  }

  public async deleteAssignmentType(id: number): Promise<any> {
    const response = this.http.delete('/api/account/' + ConfigService.accountId.getValue() + '/assignmentType/' + id);
    return await lastValueFrom(response);
  }

  /**
   * Gets my user
   * @returns
   */
  public getMyUser(): Observable<UserDetailed> {
    return this.http.get<UserDetailed>('/api/user/me');
  }

}
