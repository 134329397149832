import {inject, Injectable} from '@angular/core';
import {HttpClient}         from "@angular/common/http";
import {ConfigService}      from "../config.service";
import {lastValueFrom}      from "rxjs";
import {DashboardClass}     from "../../models/db/dashboard-class";
import {Today}              from "../../models/db/dashboard/today";
import {ClazzSummary}       from "../../models/db/dashboard/class-summary";
import {StudentSummary}     from "../../models/db/dashboard/student-summary";
import {SetupProgress}      from "../../models/db/dashboard/setup-progress";

@Injectable({
              providedIn: 'root'
            })
export class DashboardService {
  protected http = inject(HttpClient);

  constructor() {
  }

  get url(): string {
    return '/api/account/' + ConfigService.accountId.getValue()  + '/dashboard';
  }

  public async getClasses(): Promise<DashboardClass[]> {
    if (!ConfigService.accountId.getValue()){
      return [];
    }
    const response = this.http.get<DashboardClass[]>(this.url + '/classes');
    return await lastValueFrom(response);
  }

  public async getToday(): Promise<Today> {
    if (!ConfigService.accountId.getValue()){
      return {dueToday: 0, gradedDueToday: 0, gradedPastDue: 0, needsGrades: 0, pastDue: 0};
    }
    const response = this.http.get<Today>(this.url + '/today');
    return await lastValueFrom(response);
  }

  public async getClassSummary(): Promise<ClazzSummary[]> {
    if (!ConfigService.accountId.getValue()){
      return [];
    }
    const response = this.http.get<ClazzSummary[]>(this.url + '/classSummary');
    return await lastValueFrom(response);
  }

  public async getStudentSummary(): Promise<StudentSummary[]> {
    if (!ConfigService.accountId.getValue()){
      return [];
    }
    const response = this.http.get<StudentSummary[]>(this.url + '/studentSummary');
    return await lastValueFrom(response);
  }

  public async getSetupProgress(accountId: number | null): Promise<SetupProgress> {
    if (accountId == null) {
      return {classes: false, schoolYears: false, students: false, timeOff: false};
    }
    const response = this.http.get<SetupProgress>(this.url + '/setupProgress');
    return await lastValueFrom(response);
  }
}
