<div class="p-4">
  <div mat-dialog-title>{{ title }}</div>
  <mat-dialog-content [innerHTML]="message"></mat-dialog-content>
  <mat-dialog-actions>
    <div class="flex-1"></div>
    <div class="flex flex-wrap gap-2 md:gap-4">
      <md-filled-button mat-dialog-close>
        <div>
          <md-icon>cancel</md-icon>
          <span>{{ cancelText }}</span>
        </div>
      </md-filled-button>
      <md-filled-button [mat-dialog-close]="true">
        <div>
          <md-icon>check</md-icon>
          <span>{{ confirmText }}</span>
        </div>
      </md-filled-button>
    </div>
  </mat-dialog-actions>
</div>
