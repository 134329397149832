<div class="align-middle items-center flex p-1 space-x-2">

  @if (labelSide === 'left') {
    <label [for]="uniqueId">
      {{ label }}
    </label>
  }
  <md-switch (change)="onValueChange($any($event.target).selected)" [id]="uniqueId" [selected]="value" icons
             show-only-selected-icon></md-switch>
  @if (labelSide === 'right') {
    <label [for]="uniqueId">
      {{ label }}
    </label>
  }
</div>
