import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-callout',
  templateUrl: './callout.component.html',
  styleUrl: './callout.component.scss',
})
export class CalloutComponent {
  @Input() title: string = '';
  @Input() class: string = '';
  /**
   * The content of the callout. This can be a string with newlines or an array of strings.  This is used if you want to pass in line breaks.
   */
  @Input() content: string = '';

  contentLines: string[] = [];

  ngOnInit() {
    this.processContent();
  }

  processContent() {
    this.contentLines = this.content.split(/\r?\n/);
  }
}
