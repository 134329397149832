<div class="inline">
  @if (iconOnly) {
    <div (click)="openLink(); $event.stopPropagation()"
         class="icon">
      <md-icon>help</md-icon>
    </div>
  } @else {
    <div (click)="openLink(); $event.stopPropagation()"
         class="link">
      {{ label }}
      <md-icon>open_in_new</md-icon>
    </div>
  }
</div>
