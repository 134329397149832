<div class="space-y-4">
  <div class="flex">
    <div class="flex-1">
      <span class="flex gap-2">
        <h1>
          {{ title }}
        </h1>
        @if (helpUrl !== "") {
          <app-help-link [iconOnly]="true" [link]="helpUrl"></app-help-link>
        }
        </span>
      <div [innerHTML]="details" class="subdetail"></div>
    </div>
    <div class="flex space-x-2 flex-wrap lg:space-x-4 lg:space-y-0 lg:flex-row">
      <app-button (click)="back()" *ngIf="showBack" type="back"></app-button>
      <app-button (click)="new()" *ngIf="new" type="new"></app-button>
      <app-button (click)="save()" *ngIf="save" [working]="saving" [disabled]="disableSave" type="save"></app-button>
      <app-button (click)="delete()" *ngIf="delete" type="delete"></app-button>
    </div>
  </div>
  <div>
    <ng-content></ng-content>
  </div>
</div>
