import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ButtonType}                             from "../button/button.component";
import {TitleCasePipe}                          from "@angular/common";

@Component({
  selector: 'app-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrl: './icon-button.component.scss'
})
export class IconButtonComponent {
  @Input() type: ButtonType          = 'save';
  @Output() click: EventEmitter<any> = new EventEmitter();
  icon_name: string                  = 'save';

  ngOnInit() {
    if (!this.type) {
      this.type = 'save';
    }
    this.icon_name = this.type;
    switch (this.type) {
      case 'clear all':
        this.icon_name = 'clear_all';
        break;
      case 'back':
        this.icon_name = 'navigate_before';
        break;
      case 'next':
        this.icon_name = 'navigate_next';
        break;
      case 'new':
        this.icon_name = 'add';
        break;
      case 'accept':
        this.icon_name = 'check';
        break;
      case 'decline':
        this.icon_name = 'close';
        break;
      case 'message':
        this.icon_name = 'mail';
        break;
      case 'calendar':
        this.icon_name = 'calendar_today';
        break;
      case 'create':
        this.icon_name = 'add';
        break;
      default:
        this.icon_name = 'save';
    }
  }

  onClick() {
    this.click.emit();
  }
}
