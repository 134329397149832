<app-page *ngIf="user" class="pb-8"
          helpUrl="https://www.homeschoolanchor.com/help/dashboard/"
          [title]="accountType ? accountType +' Dashboard': 'Dashboard'">
  <h2>Welcome {{ user.nickName == '' ? user.firstName : user.nickName }}</h2>

  <app-callout *ngIf="browserSafari16OrEarlier">
    Your browser is not supported. Please upgrade to Safari 17+ or use a different browser.
  </app-callout>
  <app-callout *ngIf="accountIsInactive">
    Your account is currently inactive.
    <br/><br/>Assignments, calendars, classes and other features will not be available until
    you reactivate your account.
    <br/><br/>
    <a [routerLink]="routeGenService.getInactiveSubscription()">Click here to reactivate your account.</a>
  </app-callout>
  <app-callout *ngIf="accountIsTrial && isAdmin">
    <div class="flex flex-col gap-4">
      <span>You are currently in a trial.  Click here to end your trial and activate your subscription.</span>
      <app-button (click)="updateSubscription()" text="Activate Subscription" type="payments"></app-button>
      <app-spinner *ngIf="isPolling" class="w-32"></app-spinner>
      <div>If you need live one-on-one assistance getting started, click here to schedule.</div>
      <app-help-link label="Schedule Walkthrough" link="https://calendly.com/homeschool-anchor/30min"></app-help-link>
    </div>
  </app-callout>
  @if (noAccount) {
    <app-callout>
      You do not have a home account account yet.
      <br/><br/>
      Every user must have one home account. Please create one in your account settings, or accept an invite from an
      existing account.
    </app-callout>
  } @else {
    <!-- These should all be done after they've gone through the setup wizard, but if it gets missed; then it's here -->
    @if ((accountIsTrial != null && !accountIsTrial) &&
    this.setupProgress &&
    (!this.setupProgress.timeOff
      || !this.setupProgress.schoolYears
      || !this.setupProgress.students
      || !this.setupProgress.classes)) {
      <app-callout>
        Finish Setting Up Your Account<br/><br/>
        You just need to do the following things, and you'll be on your way!<br/>
        <ol class="list-decimal list-outside pl-5">

          <li [ngClass]="{'line-through': this.setupProgress.timeOff}">
            <a [routerLink]="routeGenService.getTimeOff()">
              Add in time off dates for your school year
            </a>
          </li>
          <li [ngClass]="{'line-through': this.setupProgress.schoolYears}">
            <a [routerLink]="routeGenService.getNewSchool()">
              Setup a school year
            </a>
          </li>
          <li [ngClass]="{'line-through': this.setupProgress.students}">
            <a [routerLink]="routeGenService.getNewUser()">
              Invite a student
            </a>
          </li>
          <li [ngClass]="{'line-through': this.setupProgress.classes}">
            <a [routerLink]="routeGenService.getNewClass()">
              Create a class
            </a>
          </li>
        </ol>
        <br/>
        If you need live one-on-one assistance getting started, click here to schedule.<br/>
        <app-help-link link="https://calendly.com/homeschool-anchor/30min" label="Schedule Walkthrough"></app-help-link>
      </app-callout>
    }
    <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4">
      <app-card [isLoading]="isTodayLoading"
                       class="" title="Today">
        <div *ngIf="weatherData">
          <div>
            <a [href]="weatherData.detailsUrl" class="flex space-x-4" target="_blank">
              <img [matTooltip]="weatherData.main" [src]="weatherData.iconUrl"/>
              <div class="flex flex-col">
                <span>Current Temp: {{ weatherData.temp | number:'1.0-0' }}°F</span>
                <span>Low: {{ weatherData.tempLow | number:'1.0-0' }}
                  °F High: {{ weatherData.tempHigh | number:'1.0-0' }}
                  °F</span>
              </div>
            </a>
          </div>
          <md-divider></md-divider>
        </div>
        <div *ngIf="today" class="flex flex-col gap-4">
          <a [routerLink]="'/assignments'">
            Total Assignments Today: {{ today.dueToday }}
          </a>
          <a [routerLink]="'/assignments'">
            Total Past Due: <span [class]="today.pastDue > 0 ? 'text-red-500' : ''">{{ today.pastDue }}</span>
          </a>
          <md-divider></md-divider>
          <span>Graded Assignments Due Today: {{ today.gradedDueToday }}</span>
          <span>Graded Assignments Past Due: {{ today.gradedPastDue }}</span>
          <span>Completed Assignments Needing Grades: {{ today.needsGrades }}</span>
          <app-button (click)="showRescheduleDialog = true;"
                      *ngIf="assignmentsToday > 0"
                      [disableSmallIcon]="true"
                      text="Cancel School Today"
                      type="cancel"></app-button>
        </div>
      </app-card>

      <app-card
        *ngIf="schoolYear && schoolYear.id"
        [isLoading]="isSummaryLoading"
        title="Academic Progress">
        <div class="flex flex-col">
          <a [routerLink]="routeGenService.getSchoolYear(schoolYear.id)">
            <span>Days left of the school year: {{ daysLeft }} </span>
          </a>
        </div>
        <table>
          <tr>
            <th>Active Classes</th>
            <th>Assignment Days Left</th>
          </tr>
          @for (clazz of clazzSummaries; track clazz.clazzId) {
            <tr>
              <td><a [routerLink]="routeGenService.getClassAssignments(clazz.clazzId)">{{ clazz.clazzName }}</a></td>
              <td><a
                [routerLink]="routeGenService.getClassAssignments(clazz.clazzId)">{{ clazz.assignmentsRemaining }}</a>
              </td>
            </tr>
          }
        </table>
      </app-card>

      <app-card *ngIf="user.weightedGpa || classSummaries.length > 0"
                       [isLoading]="isSummaryLoading"
                       class="flex flex-col"
                       title="My Grades">
      <span>GPA:
        @if (user.weightedGpa) {
          {{ user.weightedGpa }}
        } @else {
          No GPA yet
        }
      </span>
        <ng-container *ngIf="classSummaries.length > 0">
          <md-divider></md-divider>
          <div class="flex flex-col">
            @for (clazz of classSummaries; track clazz.clazzId) {
              <span>
              {{ clazz.clazzName }}:
                @if (clazz.percent) {
                  {{ clazz.percent | percent:'0.1-1' }}
                } @else {
                  No grade yet
                }
            </span>
            }
          </div>
        </ng-container>
      </app-card>

      @for (student of studentSummaries; track student.name.id) {
        <app-card title="{{student.name | name}} ({{student.pastDue}})"
                         [backgroundColor]="student.name.color"
                         [thumbnailUrl]="student.name.pictureUrl">
          <div class="flex flex-col gap:4">
            <span>Past Due: {{ student.pastDue }}</span>
            <span>Due Today: {{ student.dueToday }}</span>
            <span>On Time Streak: {{ student.onTrackStreak }}</span>
            <span>Longest Streak: {{ student.longestStreak }}</span>
            <md-divider></md-divider>
            <span>Current Lowest Grade</span>
            @if (student.lowestClazzName) {
              <a style="color: inherit" [routerLink]="routeGenService.getClassGrades(student.lowestClazzId)">
                <span>{{ student.lowestClazzName }}: {{ student.lowestPercentage | percent:'0.1-1' }}</span>
              </a>
            } @else {
              <span>No Grades Entered</span>
            }
          </div>
        </app-card>
      }
      @if(isAdmin){
        <app-card title="Purchase Student/Teacher Ids" [highlight]="true">
          <span>
          Get discounts at local businesses offering student and teacher discounts, including restaurants, parks, movie theaters, entertainment venues, and school supply stores.
          </span>
          <span>You can order them from each <a class="underline" [routerLink]="routerGenService.getUserList()">user's detail page.</a></span>
        </app-card>
      }
    </div>
    <app-card
      title="If you had one feature that would change your year, what would it be?"
      [expandable]="false"
      backgroundColor="#FFFFFF"
      subtitle="Book tracker, chore manager, budget builder, student ids, co-op integration, etc.">
      <div class="flex gap-4">
        <app-text-box class="flex-1" type="textarea" [(value)]="message" [rows]="3"></app-text-box>
        <app-button (click)="sendFeedback()" text="Send" type="message"></app-button>
      </div>
    </app-card>
  }
  <app-feature-request></app-feature-request>
</app-page>
<app-dialog *ngIf="account && !account.isInitiallyReviewed" [allowClose]="false" title="Confirm Details">
  <ng-template>
    Please confirm the following information is correct:
    <app-text-box [(value)]="account.name" class="w-64 md:w-80"
                  helpText="This is your school name.  It will show up on reports like transcripts.<br/><br/>You can change it later under your account settings.<br/><br/>Ex: Smith Family Homeschool"
                  label="School Name"></app-text-box>
    <app-select [(value)]="account.timeZone" [options]="timeZoneOptions" class="w-64 md:w-80"
                helpText="This is used in calendar functionality.<br/><br/>You can change it later under your account settings."
                label="Time Zone"></app-select>
    <div class="flex">
      <div class="flex-1"></div>
      <app-button (click)="accountReviewed()" text="Confirm" type="save"></app-button>
    </div>
  </ng-template>
</app-dialog>

<app-dialog *ngIf="showHomeschoolSetup" [allowClose]="isSuperuser" title="Welcome to the Anchor family!">
  <ng-template>
    <mat-stepper #stepper [linear]="true" orientation="vertical">
      <mat-step label="Welcome!!!">
        <div class="mt-4 flex flex-col gap-4">
          <span> We're thrilled to support you on your homeschooling adventure!<br/><br/> In just a few simple steps, we'll guide you through setting up your account with a common configuration. Plus, we've included a few sample classes to help you get a feel for what your experience will be like. </span>
          <div class="flex gap-4">
            <app-button (click)="stepper.next()" type="next"></app-button>
          </div>
        </div>
      </mat-step>
      <mat-step [completed]="homeschoolSetup.startDate != ''" label="Schedule">
        <div class="flex flex-col gap-4 mt-4">
          <span>Enter some basic information about your schedule.</span>
          <h3>School Year Start Date</h3>
          <span>We'll start by setting you up with a standard 180-day school year, complete with typical holidays and vacation days for your state. Don’t worry, you can easily adjust these later to fit your unique schedule.</span>
          <app-date-box [(value)]="homeschoolSetup.startDate" [required]="true" label="Start Date"></app-date-box>
          <h3>Class Days of the Week</h3>
          <span>We'll create your sample classes with these class days</span>
          <div class="-ml-4 items-center flex flex-wrap">
            <app-switch [(value)]="homeschoolSetup.onSunday" class="ml-4" label="Sun"></app-switch>
            <app-switch [(value)]="homeschoolSetup.onMonday" class="ml-4" label="Mon"></app-switch>
            <app-switch [(value)]="homeschoolSetup.onTuesday" class="ml-4" label="Tue"></app-switch>
            <app-switch [(value)]="homeschoolSetup.onWednesday" class="ml-4" label="Wed"></app-switch>
            <app-switch [(value)]="homeschoolSetup.onThursday" class="ml-4" label="Thu"></app-switch>
            <app-switch [(value)]="homeschoolSetup.onFriday" class="ml-4" label="Fri"></app-switch>
            <app-switch [(value)]="homeschoolSetup.onSaturday" class="ml-4" label="Sat"></app-switch>
          </div>
          <div class="flex gap-4">
            <app-button (click)="stepper.previous()" type="back"></app-button>
            <app-button (click)="stepper.next()" [disabled]="homeschoolSetup.startDate == ''" type="next"></app-button>
          </div>
        </div>
      </mat-step>
      <mat-step [completed]="!emailError &&
      homeschoolSetup.studentFirstName!='' &&
      homeschoolSetup.studentLastName!='' &&
      homeschoolSetup.studentBirthDate !=''"
                label="First Student Info">
        <div class="flex flex-col gap-4 flex-wrap mt-4">
          <div class="flex flex-wrap gap-4">
            <span>
              Let's get started by entering your first student's details. We'll send an account invite to their email so they can log in.<br/><br/>
              They'll have default permissions, which will allow them to view their calendar, assignments, and classes, as well as mark assignments as complete.
            </span>
            <app-callout title="What if my kids don't have an email?">
              <span>
                We understand that not all children, especially younger ones, have email accounts. However, each user does need an email to access the system.
              </span>
              <span class="text-red-700 italic">
                Don't worry, though—your kids don’t need to check their email. You can simply create a Gmail account for them that we’ll use to sign them in. You can keep the password, so they don’t need access to Gmail itself.
              </span>
              <span>
                Having separate logins helps keep everything secure, so they won’t be able to accidentally change or delete assignments, grades, users, or classes.
              </span>
              <span>
                If your child doesn’t have an email yet, you can create a free one through
                <a href="https://www.gmail.com" target="_blank">Gmail</a> or
                <a href="https://www.outlook.com" target="_blank">Outlook</a>.
              </span>
            </app-callout>

            <app-text-box (blur)="validateEmail($event)" [(value)]="homeschoolSetup.studentEmail"
                          [errorMessage]="emailErrorMessage"
                          [error]="emailError"
                          [required]="true" class="w-full" label="Email"></app-text-box>
            <app-text-box [(value)]="homeschoolSetup.studentFirstName" [required]="true"
                          label="First Name"></app-text-box>
            <app-text-box [(value)]="homeschoolSetup.studentLastName" [required]="true"
                          label="Last Name"></app-text-box>
            <app-select [(value)]="homeschoolSetup.studentGrade"
                        [options]="gradeOptions"
                        label="Current Grade"></app-select>
            <app-date-box [(value)]="homeschoolSetup.studentBirthDate" [required]="true"
                          label="Birth Date"></app-date-box>
          </div>
          <div class="flex gap-4">
            <app-button (click)="stepper.previous()" type="back"></app-button>
            <app-button (click)="stepper.next()" [disabled]="homeschoolSetup.studentFirstName == '' ||
      homeschoolSetup.studentFirstName=='' ||
      homeschoolSetup.studentLastName=='' ||
      homeschoolSetup.studentBirthDate ==''" type="next"></app-button>
          </div>
        </div>
      </mat-step>
      <mat-step label="Tips">
        <div class="flex flex-col gap-4 flex-wrap mt-4">
          <div> Once we’ve got your basic setup in place, here are a few tips to help you get started:
            <ol class="list-decimal pl-5 gap-2 flex flex-col mt-4">
              <li>When you're ready, feel free to delete or modify the sample setup data (like classes).</li>
              <li>If you need to reschedule classes or assignments, the calendar view is your go-to spot.</li>
              <li>Keep an eye out for the help bubble on each page for more detailed guidance.</li>
              <li>You can add and manage your children in the "Users" section. You can even adjust their privileges,
                like allowing them to grade their own assignments.
              </li>
              <li>Assignments are easily managed within their class under their assignment tab.</li>
              <li>If you need assistance, we're here to help! Just send us an email or click 'Schedule Support' in the
                top-right menu.
              </li>
            </ol>
          </div>
          <div class="flex gap-4">
            <app-button (click)="stepper.previous()" type="back"></app-button>
            <app-button (click)="stepper.next()" type="next"></app-button>
          </div>
        </div>
      </mat-step>
      <mat-step label="Confirmation">
        <div class="mt-4 flex flex-col gap-4">
          <div> When you click "Finish," here’s what we’ll take care of for you:
            <ol class="list-decimal pl-5 gap-2 flex flex-col mt-4">
              <li>Create typical time-off days based on your state's schedule.</li>
              <li>Set up the school year.</li>
              <li>Create a student account. Your student will receive an email invitation to Anchor with permissions to
                view their schedule, assignments, and mark assignments as complete.
              </li>
              <li>Create 3 sample classes (Math, Science, and English) with sample assignments.</li>
              <li>Enroll your child as a student in these classes.</li>
              <li>Complete 3 sample assignments for your student, so you can see how everything works.</li>
            </ol>
          </div>
          <div class="flex">
            @if (isConfiguringSetup) {
              <app-spinner></app-spinner>
            } @else {
              <app-button (click)="setupHomeschool()" text="Finish" type="complete"></app-button>
            }
          </div>
        </div>
      </mat-step>
    </mat-stepper>
  </ng-template>
</app-dialog>
<!--
<app-dialog-reschedule (close)="closeCancel();" *ngIf="showRescheduleDialog"></app-dialog-reschedule>
-->

