import {Pipe, PipeTransform} from '@angular/core';
import {User}                from "../models/db/user";
import {Name}                from "../models/db/name";

@Pipe({
        name: 'name',
        standalone: true
      })
export class NamePipe implements PipeTransform {

  transform(value: User | Name, ...args: unknown[]): string {
    return (value.nickName == '' ? value.firstName : value.nickName) + ' ' + value.lastName;
  }

}
