import { Component, Injectable, Inject } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarRef,
  MatSnackBarModule,
  MAT_SNACK_BAR_DATA,
  TextOnlySnackBar,
  SimpleSnackBar,
} from '@angular/material/snack-bar';
@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private _snackBar: MatSnackBar) {}
  durationInSeconds = 5;

  public warning(message: string) {
    this.showSnackBar(message, "warning");
  }

  public success(message: string) {
    this.showSnackBar(message, "success");
  }

  public error(message: string) {
    this.showSnackBar(message, "error");
  }

  private showSnackBar(message: string, type: string) {
    let sb = this._snackBar.open(message, "Dismiss",
     {
       duration: this.durationInSeconds * 1000,
       panelClass: type + "-snackbar"
     });
     sb.onAction().subscribe(() => {
       sb.dismiss();
     });
  }
}

