import {booleanAttribute, Component, EventEmitter, HostBinding, Input, Output} from '@angular/core';

@Component({
  selector: 'app-date-box',
  templateUrl: './date-box.component.html',
  styleUrl: './date-box.component.scss'
})
export class DateBoxComponent {
  @Input() label: string = '';
  @Input() value: string = '';
  @Output() valueChange  = new EventEmitter<string>();
  public _value: Date | null            = null;
  @HostBinding('style.display') display = 'inline-block';
  protected readonly console = console;
  @Input({transform: booleanAttribute}) required = false;

  ngOnInit() {
    this._value = this.parseDate(this.value);
  }

  onValueChange(value: string) {
    setTimeout(() => {
      if (!value) {
        this.valueChange.emit('');
      } else {
        const date = new Date(value);
        this.valueChange.emit(date.toISOString().split('T')[0]);
      }
    }, 1);
  }

  ngOnChanges(changes: any) {
    if (changes.value) {
      this._value = this.parseDate(this.value);
    }
    //this._value = this.parseDate(this.value);

  }

  parseDate(input: string) {

    const dateRegex = /^\d{4}-\d{2}-\d{2}$/;

    if (!dateRegex.test(input)) {
      return null;
    }

    const parts = input.split('-');
    const year  = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1;
    const day   = parseInt(parts[2], 10);
    // Please note that the month is 0-based in JavaScript Date objects, so we subtract 1.
    return new Date(year, month, day);
  }
}
