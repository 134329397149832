import {inject, Injectable} from '@angular/core';
import {HttpClient}         from "@angular/common/http";
import {ConfigService}      from "../config.service";
import {lastValueFrom}      from "rxjs";
import {SchoolYear}         from "../../models/db/school-year";

@Injectable({
              providedIn: 'root'
            })
export class SchoolYearService {
  private http = inject(HttpClient);

  public async list(): Promise<SchoolYear[]> {
    if (ConfigService.accountId.getValue() == null) {
      return [];
    }
    const response = this.http.get<SchoolYear[]>('/api/account/' + ConfigService.accountId.getValue() + '/schoolYear');
    return await lastValueFrom(response);
  }

  public async create(object: SchoolYear): Promise<SchoolYear> {
    const response = this.http.post<SchoolYear>('/api/account/' + ConfigService.accountId.getValue() + '/schoolYear', object);
    return await lastValueFrom(response);
  }

  public async update(object: SchoolYear): Promise<SchoolYear> {
    const response = this.http.put<SchoolYear>('/api/account/' + ConfigService.accountId.getValue() + '/schoolYear', object);
    return await lastValueFrom(response);
  }

  public async delete(id: number): Promise<any> {
    const response = this.http.delete('/api/account/' + ConfigService.accountId.getValue() + '/schoolYear/' + id);
    return await lastValueFrom(response);
  }

  public async get(id: number): Promise<SchoolYear> {
    const response = this.http.get<SchoolYear>('/api/account/' + ConfigService.accountId.getValue() + '/schoolYear/' + id);
    return await lastValueFrom(response);
  }

  public async getCurrent(): Promise<SchoolYear> {
    const response = this.http.get<SchoolYear>('/api/account/' + ConfigService.accountId.getValue() + '/schoolYear/current');
    return await lastValueFrom(response);
  }
}
