export class UserClazz {
	userId: number;
	clazzId: number;
	isStudent: boolean;
	isTeacher: boolean;
  percent : number;
  gpa: number;
  letterGrade: string;
  reportedName: string;
  reportedLetterGrade: string;
  reportedGpa: number;
  reportedPercentage: number;
  weightedGpa: number;

	constructor() {
		this.userId = -1;
		this.clazzId = -1;
		this.isStudent = false;
		this.isTeacher = false;
    this.percent = 0;
    this.gpa = 0;
    this.letterGrade = '';
    this.reportedName        = '';
    this.reportedLetterGrade = '';
    this.reportedGpa        = 0;
    this.reportedPercentage = 0;
    this.weightedGpa        = 0;
	}
}
